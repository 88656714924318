import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Autocomplete,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIndividualProfile,
  individualUserSelector,
  logOut,
  updateIndividual,
} from "../api/individualUser";
import { Logout } from "@mui/icons-material";
import Logo from "../images/logo.png";
import {
  FormControl,
  Grid,
  InputLabel,
  Menu,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import {
  Keyareas,
  subCategory,
  category,
  studentSubcategories,
} from "../shared/constants";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import storage from "../shared/firebaseStorage";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { Textarea, styled } from "@mui/joy";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Button from "@mui/joy/Button";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import logo from "../images/slogo.png";
import { nanoid } from "@reduxjs/toolkit";
import useRazorpay from "react-razorpay";

import { keyUri, config } from "../key";
import axios from "axios";

// const steps = [
//   "Basic Details",
//   "Education Details",
//   "Work Experience Details",
//   "Achievements and Awards",
//   "Skills  ",
//   "Activate Your Subscription",
//   "Active Plans",
// ];

const steps = [
  "",
  "",
  "",
  "",
  "  ",
  "  ",
  " ",
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export default function Profile() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { user, individualtoken } = useSelector(individualUserSelector);
  const [openMod, setOpenMod] = React.useState(false);

  const [period, setPeriod] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [Razorpay] = useRazorpay();
  const [errorMessage, setErrorMessage] = useState("");
  const handleOpenMod = () => setOpenMod(true);

  const [characterLimit, setCharacterLimit] = useState(
    "Your description cannot exceed 160 characters and above."
  );

  const [characterLimit1, setCharacterLimit1] = useState(
    "Your description cannot exceed 500 characters and above."
  );

  const [characterLimit2, setCharacterLimit2] = useState(
    "Your description cannot exceed 380 characters and above."
  );

  useEffect(() => {
    amountCalculation();
  }, [user]);


  const fetchPeriod = async (id) => {
    try {
      const response = await axios.get(
        `${keyUri.BACKEND_URI}/users/period/${id}`
      );
      setPeriod(response.data.period);
      // //console.log(response.data.period, "response period");
    } catch (error) {
      console.error("Error fetching period:", error);
    }
  };

  useEffect(() => {
    if (user?._id) {
      fetchPeriod(user?._id); // Initial fetch
    }
  }, [user?._id]);

  useEffect(() => {
 
    if (individualtoken) {
      dispatch(fetchIndividualProfile(individualtoken));
    }
  }, [individualtoken]);

  function amountCalculation() {
    if (user?.profile_completion.category === "Student") {
      setPaymentAmount(user?.plan[0]?.price);
    } else {
      setPaymentAmount(user?.plan[0]?.price);
    }
  }

  const receiptId = "qwsaq1";
  const currencyInr = "INR";

  const handlePayment = async () => {
    // //console.log(window.Razorpay);
    // //console.log(user.email);

    try {
      const finalAmt = Number(paymentAmount) * 100; // Convert amount to smallest unit (e.g., cents)
      // //console.log("Sending payment request...");

      // Create the order on the backend
      const response = await fetch(`${keyUri.BACKEND_URI}/order`, {
        method: "POST",
        body: JSON.stringify({
          amount: finalAmt,
          currency: currencyInr,
          receipt: receiptId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const order = await response.json();
      // //console.log("Order created:", order);

      // Setup Razorpay options
      const options = {
        key: "rzp_live_DdtLCdVoEvVBt1", // Your Razorpay Key ID
        amount: finalAmt,
        currency: currencyInr,
        name: "BHBHARAT RESEARCHERS CLOUD",
        description: "Test Transaction",
        image: logo,
        order_id: order.id,
        handler: async function (response) {
          // Validate payment on the server
          const body = {
            ...response,
            email: user.email,
            amount: finalAmt,
            currency: currencyInr,
          };

          const validateRes = await fetch(
            `${keyUri.BACKEND_URI}/order/validate`,
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          // Parse server response
          const jsonRes = await validateRes.json();
          // //console.log(jsonRes, "this is json response validate");
          nav("/home");

          if (jsonRes.success) {
            // Assuming success indicates successful validation
            // Fetch the updated period and update state
            fetchPeriod(user._id);
          }
        },
        prefill: {
          name: "BHARAT RESEARCHERS CLOUD",
          email: "info@bharc.in",
        },
        theme: {
          color: "#e0f3fa",
        },
      };

      // Initialize and open Razorpay payment gateway
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });

      rzp1.open();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };
  const [formData, setFormData] = React.useState({
    category: "",
    career_objectives: "",
    education_level: "",
    institute_name: "",
    designation: "",
    company_name: "",
    company_location: "",
    city: "",
    state: "",
 
   
  });

  const [eduForm, setEduForm] = React.useState({
    institute_name1: "",
    education_level1: "",
    course_name1: "",
    institute_name2: "",
    education_level2: "",
    course_name2: "",
    start_year1: "",
    end_year1: "",
    expStart_year2: "",
    expEnd_year2: "",

  });


  const [errors, setErrors] = useState({
    institute_name1: "",
    education_level1: "",
    course_name1: "",
    start_year1: "",
    end_year1: "",
    institute_name2: "",
    education_level2: "",
    course_name2: "",
    start_year2: "",
    end_year2: "",
  });

  //education details yesr useStates
  const [start_year1, setStart_year1] = useState(null);
  const [end_year1, setEnd_year1] = useState(null);
  const [start_year2, setStart_year2] = useState(null);
  const [end_year2, setEnd_year2] = useState(null);

  const [expForm, setExpForm] = React.useState({
    company_name1: "",
    designation1: "",
    company_location1: "",
    expStart_year1: "",
    expEnd_year1: "",
    company_name2: "",
    designation2: "",
    company_location2: "",
    expStart_year2: "",
    expEnd_year2: "",
  });

  const [expStart_year1, setExpStart_year1] = useState(null);
  const [expEnd_year1, setExpEnd_year1] = useState(null);
  const [expStart_year2, setExpStart_year2] = useState(null);
  const [expEnd_year2, setExpEnd_year2] = useState(null);

  const [awardForm, setAwardForm] = React.useState({
    award1: "",
    awarded_year1: "",
    award2: "",
    awarded_year2: "",
    achivement1: "",
    achivement2: "",
    achived_year1: "",
    achived_year2: "",
  });

  const [awarded_year1, setAwarded_year1] = useState(null);

  const [achived_year1, setAchived_year1] = useState(null);

  const [awarded_year2, setAwarded_year2] = useState(null);

  const [achived_year2, setAchived_year2] = useState(null);

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [document, setDocument] = React.useState(null);
  const [percent, setPercent] = React.useState(0);
  const [uploadSuccessMsg, setLogoUploadSuccess] = React.useState("");
  const [profilePic, setprofilePic] = React.useState(0);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [error6, setError6] = useState(false);
  const [error7, setError7] = useState(false);
  const [error8, setError8] = useState(false);
  const [error9, setError9] = useState(false);
  const [error10, setError10] = useState(false);

  //date picker year restrictor
  const isInCurrentYear = (date) => date.get("year") > dayjs().get("year");

  //step useStates
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  //Autocomplete
  const [selectedkeyArea, setSelectedkeyArea] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [visible, setVisible] = React.useState(false);

  //logout dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleComplete = () => {
    // if (activeStep === 0) {
    // Existing validation and submission logic for step 0
    const {
      institute_name1,
      education_level1,
      course_name1,
      institute_name2,
      education_level2,
      course_name2,
    } = eduForm;

    const {
      company_name1,
      designation1,
      company_location1,
      company_name2,
      designation2,
      company_location2,

    } = expForm;

    const { award1, achivement1, award2, achivement2 } = awardForm;

    if (formData.category !== "") {
      if (formData.category === "Student") {
        if (
          formData.education_level !== "" &&
          formData.institute_name !== "" &&
          formData.city !== "" &&
          formData.state !== "" &&
          skills.length !== 0
        ) {
          var studentDetails = formData;
          delete studentDetails.designation;
          delete studentDetails.company_name;
          const data = {
            profile_completion: {
              ...studentDetails,
              skills,
              profile_pic: document,
              education_details_1: {
                institute_name1,
                education_level1,
                course_name1,
                start_year1,
                end_year1,
              },
              education_details_2: {
                institute_name2,
                education_level2,
                course_name2,
                start_year2,
                end_year2,
              },
              experience_details_1: {
                company_name1,
                designation1,
                company_location1,
                expStart_year1,
                expEnd_year1,
              },
              experience_details_2: {
                company_name2,
                designation2,
                company_location2,
                expStart_year2,
                expEnd_year2,
              },
              award_details_1: {
                award1,
                awarded_year1,
              
              },
              award_details_2: {
                award2,
                awarded_year2,
              },
              achivement_details_1: {
                achivement1,
                achived_year1,
              
              },

              achivement_details_2: {
                achivement2,
                achived_year2,
              },
              status: true,
            },
          };
          if (
            !error1 &&
            !error2 &&
            !error3 &&
            !error4 &&
            !error5 &&
            !error6 &&
            !error7 &&
            !error8 &&
            !error9 &&
            !error10
          ) {
            onFinish(user?._id, data);
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            handleNext();
          } else {
            toast.warning("Please fill valid year", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeButton: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else {
          showToast();
        }
      }

      if (formData.category !== "" && formData.category !== "Student") {
        if (
          formData.designation !== "" &&
          formData.company_name !== "" &&
          formData.city !== "" &&
          formData.state !== "" &&
          skills.length !== 0
        ) {
          var empDetails = formData;
          delete empDetails.education_level;
          delete empDetails.institute_name;
          delete empDetails.career_objectives;



          const data = {
            profile_completion: {
              ...empDetails,
              skills,
              profile_pic: document,
              education_details_1: {
                institute_name1,
                education_level1,
                course_name1,
                start_year1,
                end_year1,
              },
              education_details_2: {
                institute_name2,
                education_level2,
                course_name2,
                start_year2,
                end_year2,
              },
              experience_details_1: {
                company_name1,
                designation1,
                company_location1,
                expStart_year1,
                expEnd_year1,
              },
              experience_details_2: {
                company_name2,
                designation2,
                company_location2,
                expStart_year2,
                expEnd_year2,
              },
              award_details_1: {
                award1,
                awarded_year1,
              
              },
              award_details_2: {
                award2,
                awarded_year2,
              },
              achivement_details_1: {
                achivement1,
                achived_year1,
              
              },

              achivement_details_2: {
                achivement2,
                achived_year2,
              },
              status: true,
            },
          };
          if (
            !error1 &&
            !error2 &&
            !error3 &&
            !error4 &&
            !error5 &&
            !error6 &&
            !error7 &&
            !error8 &&
            !error9 &&
            !error10
          ) {
            onFinish(user?._id, data);
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            handleNext();
          } else {
            toast.warning("Please fill valid year", {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeButton: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else {
          showToast();
        }
      }

      if (completedSteps() === totalSteps()) {
        nav("/home");
      }
    } else {
      toast.warning("Please fill all fields", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

 
  };

  //Autocomplete useEffect
  React.useEffect(() => {
    const keyInterst = selectedkeyArea?.map((item) => {
      return item?.title;
    });
    setSkills(keyInterst);
  }, [selectedkeyArea]);

  //prefill useEffect
  React.useEffect(() => {
    // step1 edit prefill
    setFormData({
      category: user?.profile_completion?.category
        ? user?.profile_completion?.category
        : "",
      career_objectives: user?.profile_completion?.career_objectives
        ? user?.profile_completion?.career_objectives
        : "",
      education_level: user?.profile_completion?.education_level
        ? user?.profile_completion?.education_level
        : "",
      institute_name: user?.profile_completion?.institute_name
        ? user?.profile_completion?.institute_name
        : "",
      designation: user?.profile_completion?.designation
        ? user?.profile_completion?.designation
        : "",
      company_name: user?.profile_completion?.company_name
        ? user?.profile_completion?.company_name
        : "",
      city: user?.profile_completion?.city
        ? user?.profile_completion?.city
        : "",
      state: user?.profile_completion?.state
        ? user?.profile_completion?.state
        : "",
    });

    setDocument(user?.profile_completion?.profile_pic);
    setSkills(user?.profile_completion?.skills);
    user?.profile_completion?.skills?.length != 0
      ? setSelectedkeyArea(
          user?.profile_completion?.skills?.map((item) => {
            return { title: item };
          })
        )
      : setSelectedkeyArea([]);
    user?.profile_completion?.skills?.length != 0
      ? setTimeout(() => {
          setVisible(true);
        }, [1000])
      : setVisible(true);

    // step2 edit prefill
    setEduForm({
      institute_name1: user?.profile_completion?.education_details_1
        ?.institute_name1
        ? user?.profile_completion?.education_details_1?.institute_name1
        : "",
      education_level1: user?.profile_completion?.education_details_1
        ?.education_level1
        ? user?.profile_completion?.education_details_1?.education_level1
        : "",
      course_name1: user?.profile_completion?.education_details_1?.course_name1
        ? user?.profile_completion?.education_details_1?.course_name1
        : "",
      institute_name2: user?.profile_completion?.education_details_2
        ?.institute_name2
        ? user?.profile_completion?.education_details_2?.institute_name2
        : "",
      education_level2: user?.profile_completion?.education_details_2
        ?.education_level2
        ? user?.profile_completion?.education_details_2?.education_level2
        : "",
      course_name2: user?.profile_completion?.education_details_2?.course_name2
        ? user?.profile_completion?.education_details_2?.course_name2
        : "",

    });

    setStart_year1(
      user?.profile_completion?.education_details_1?.start_year1
        ? user?.profile_completion?.education_details_1?.start_year1
        : null
    );



    setEnd_year1(
      user?.profile_completion?.education_details_1?.end_year1
        ? user?.profile_completion?.education_details_1?.end_year1
        : null
    );

    setStart_year2(
      user?.profile_completion?.education_details_2?.start_year2
        ? user?.profile_completion?.education_details_2?.start_year2
        : null
    );
    setEnd_year2(
      user?.profile_completion?.education_details_2?.end_year2
        ? user?.profile_completion?.education_details_2?.end_year2
        : null
    );

    //step3 edit prefill
    setExpForm({
      company_name1: user?.profile_completion?.experience_details_1
        ?.company_name1
        ? user?.profile_completion?.experience_details_1?.company_name1
        : "",
      designation1: user?.profile_completion?.experience_details_1?.designation1
        ? user?.profile_completion?.experience_details_1?.designation1
        : "",
      company_location1: user?.profile_completion?.experience_details_1
        ?.company_location1
        ? user?.profile_completion?.experience_details_1?.company_location1
        : "",
      company_name2: user?.profile_completion?.experience_details_2
        ?.company_name2
        ? user?.profile_completion?.experience_details_2?.company_name2
        : "",
      designation2: user?.profile_completion?.experience_details_2?.designation2
        ? user?.profile_completion?.experience_details_2?.designation2
        : "",
      company_location2: user?.profile_completion?.experience_details_2
        ?.company_location2
        ? user?.profile_completion?.experience_details_2?.company_location2
        : "",
    });

    setExpStart_year1(
      user?.profile_completion?.experience_details_1?.expStart_year1
        ? user?.profile_completion?.experience_details_1?.expStart_year1
        : null
    );
    setExpEnd_year1(
      user?.profile_completion?.experience_details_1?.expEnd_year1
        ? user?.profile_completion?.experience_details_1?.expEnd_year1
        : ""
    );
    setExpStart_year2(
      user?.profile_completion?.experience_details_2?.expStart_year2
        ? user?.profile_completion?.experience_details_2?.expStart_year2
        : null
    );
    setExpEnd_year2(
      user?.profile_completion?.experience_details_2?.expEnd_year2
        ? user?.profile_completion?.experience_details_2?.expEnd_year2
        : null
    );

    //step4 edit prefill
    setAwardForm({
      award1: user?.profile_completion?.award_details_1?.award1
        ? user?.profile_completion?.award_details_1?.award1
        : "",
      award2: user?.profile_completion?.award_details_2?.award2
        ? user?.profile_completion?.award_details_2?.award2
        : "",
      achivement1: user?.profile_completion?.achivement_details_1?.achivement1
        ? user?.profile_completion?.achivement_details_1?.achivement1
        : "",
      achivement2: user?.profile_completion?.achivement_details_2?.achivement2
        ? user?.profile_completion?.achivement_details_2?.achivement2
        : "",
    });

    setAwarded_year1(
      user?.profile_completion?.award_details_1?.awarded_year1
        ? user?.profile_completion?.award_details_1?.awarded_year1
        : null
    );
    setAwarded_year2(
      user?.profile_completion?.award_details_2?.awarded_year2
        ? user?.profile_completion?.award_details_2?.awarded_year2
        : null
    );

    setAchived_year1(
      user?.profile_completion?.achivement_details_1?.achived_year1
        ? user?.profile_completion?.achivement_details_1.achived_year1
        : null
    );

    setAchived_year2(
      user?.profile_completion?.achivement_details_2?.achived_year2
        ? user?.profile_completion?.achivement_details_2.achived_year2
        : null
    );
    // setLogoUploadSuccess('')
    // setPercent(100)
  }, [user]);

  React.useEffect(() => {
    // //console.log(user?.profile_completion?.status);
    if (
      user?.profile_completion?.status === true ||
      user?.profile_completion?.status == undefined
    ) {
      nav("/home");
    }
  }, []);

  //Autocomplete function
  const handleKeyAreaSelection = (value) => {
    if (value.length <= 4) {
      setSelectedkeyArea([...value]);
    } else {
      toast.warning("You can only select 4 domains", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //logout dropdown function
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //step handling functions
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (
      !error1 &&
      !error2 &&
      !error3 &&
      !error4 &&
      !error5 &&
      !error6 &&
      !error7 &&
      !error8 &&
      !error9 &&
      !error10
    ) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    } else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  //react tostify
  const showToast = () => {
    toast.warning("Please fill all the fields!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

 
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  //picture upload function
  const handleFileInputChange = (event) => {
    const newSelectedFile = event.target.files[0];
    setSelectedFile(newSelectedFile);
    if (newSelectedFile) {
      const isJpgOrPng =
        newSelectedFile.type === "image/jpeg" ||
        newSelectedFile.type === "image/png";
      if (!isJpgOrPng) {
        toast.warning("You can only upload JPG/PNG file!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const isLt100KB = newSelectedFile.size / 1024 < 100;
      if (!isLt100KB) {
        toast.warning("Image size must be less than 100kb", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeButton: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (isJpgOrPng && isLt100KB) {
        const storageRef = ref(
          storage,
          `/Individual Profile Completion/${nanoid() + newSelectedFile.name}`
        );
        const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);
        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
            setprofilePic(percent);
            if (percent == 100)
              setLogoUploadSuccess("Profile picture uploaded successfully.");
          },
          (err) => {
            console.error(err);
          },
            () => {
              // download url
              getDownloadURL(uploadFile.snapshot.ref).then((url) => {
                setDocument(url);
              });
            }
        );
      }
    }
  };

  const handleDeleteClickLogo = () => {
    if (document) {
      const firebaseStorageURL = document;
      const urlParts = firebaseStorageURL.split("?");
      const pathParts = urlParts[0].split("/");
      const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
      const desertRef = ref(storage, `${fileName}`);
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
          setSelectedFile(null);
          setDocument(null);
          setPercent(0);
          setLogoUploadSuccess("");
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          // //console.log(error);
        });
    }

    setDocument(null);
    setPercent(0);
    setLogoUploadSuccess("");
  };

  //step1 form changing function
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name == "career_objectives" && value.length <= 380) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (name != "career_objectives") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  //step2 form changing function
  const handelEduFormChange = (event) => {
    const { name, value } = event.target;

    setEduForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //step3 form changing function
  const handleExpFormChange = (event) => {
    const { name, value } = event.target;

    setExpForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //step4 form changing function
  const handleAwardFormChange = (event) => {
    const { name, value } = event.target;
    if (value.length <= 160 && name == "award1") {
      setAwardForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (value.length <= 160 && name == "award2") {
      setAwardForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (value.length <= 500 && name == "achivement1") {
      setAwardForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (value.length <= 500 && name == "achivement2") {
      setAwardForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const onFinish = (id, data) => {
 
    dispatch(updateIndividual(id, data, individualtoken));
      
  };

  
  const handelEduYear1 = (event) => {
    console.log(event, "event")
    setError1(false);

    if (event == null || event == undefined) {
      setStart_year1(0);
    } else {
      setStart_year1(event);

      if (event?.get("year") > dayjs().get("year")) {
        setError1(true);
        toast.error("Start year cannot be in the future");
      } else if (
        start_year2 &&
        event?.get("year") === start_year2.get("year")
      ) {
        setError1(true);
        toast.error(
          "Latest Education and Previous Education Start Years not be the Same"
        );
      } else {
        setError1(false); // Clear error if valid
      }

      // Update error for end year if start year changes
      if (end_year1 && event?.get("year") > end_year1.get("year")) {
        setError2(true);
        toast.error("End year cannot be before start year");
      } else {
        setError2(false); // Clear error if valid
      }
    }
  };

  const handelEduYear2 = (event) => {
    setError2(false);

    if (event == null || event == undefined) {
      setEnd_year1(0);
    } else {
      setEnd_year1(event);

      if (event?.get("year") > dayjs().get("year")) {
        setError2(true);
        toast.error("End year cannot be in the future");
      } else if (start_year1 && event?.get("year") < start_year1.get("year")) {
        setError2(true);
        toast.error("End year cannot be before start year");
      } else if (end_year2 && event?.get("year") === end_year2.get("year")) {
        setError2(true);
        toast.error(
          "Previous Education and Latest Education   End Years not be the Same"
        );
      } else {
        setError2(false); // Clear error if valid
      }

      // Update error for start year if end year changes
      if (start_year1 && event?.get("year") <= start_year1.get("year")) {
        setError1(true);
        toast.error("Start year cannot be after end year");
      } else {
        setError1(false); // Clear error if valid
      }
    }
  };

  const handelEduYear3 = (event) => {
    setError3(false);

    if (event == null || event == undefined) {
      setStart_year2(0);
    } else {
      setStart_year2(event);

      if (event?.get("year") > dayjs().get("year")) {
        setError3(true);
        toast.error("Start year cannot be in the future");
      } else if (
        start_year1 &&
        event?.get("year") === start_year1.get("year")
      ) {
        setError3(true);
        toast.error(
          "Latest Education and Previous Education Start Years not be the Same"
        );
      } else {
        setError3(false); // Clear error if valid
      }

      // Update error for end year if start year changes
      if (end_year2 && event?.get("year") > end_year2.get("year")) {
        setError4(true);
        toast.error("End year cannot be before start year");
      } else {
        setError4(false); // Clear error if valid
      }
    }
  };

  const handelEduYear4 = (event) => {
    setError4(false);

    if (event == null || event == undefined) {
      setEnd_year2(0);
    } else {
      setEnd_year2(event);

      if (event?.get("year") > dayjs().get("year")) {
        setError4(true);
        toast.error("End year cannot be in the future");
      } else if (start_year2 && event?.get("year") < start_year2.get("year")) {
        setError4(true);
        toast.error("End year cannot be before start year");
      } else if (end_year1 && event?.get("year") === end_year1.get("year")) {
        setError4(true);
        toast.error(
          "Previous and Education Latest Education  End Years not be the Same"
        );
      } else {
        setError4(false); // Clear error if valid
      }

      // Update error for start year if end year changes
      if (start_year2 && event?.get("year") <= start_year2.get("year")) {
        setError3(true);
        toast.error("Start year cannot be after end year");
      } else {
        setError3(false); // Clear error if valid
      }
    }
  };

 
  //   setError5(false);
  //   if (event == null || event == undefined) {
  //     setExpStart_year1(0);
  //   } else {
  //     setExpStart_year1(event);
  //     setError5(false);
  //   }
  //   if (event?.get("year") > dayjs().get("year")) {
  //     setError5(true);
  //   }
  // };

  // const handelExpYear6 = (event) => {
  //   setError6(false);
  //   if (event == null || event == undefined) {
  //     setExpEnd_year1(0);
  //   } else {
  //     setExpEnd_year1(event);
  //     setError6(false);
  //   }
  //   if (event?.get("year") > dayjs().get("year")) {
  //     setError6(true);
  //   }
  // };

  // const handelExpYear7 = (event) => {
  //   setError7(false);
  //   if (event == null || event == undefined) {
  //     setExpStart_year2(0);
  //   } else {
  //     setExpStart_year2(event);
  //     setError7(false);
  //   }
  //   if (event?.get("year") > dayjs().get("year")) {
  //     setError7(true);
  //   }
  // };

  // const handelExpYear8 = (event) => {
  //   setError8(false);
  //   if (event == null || event == undefined) {
  //     setExpEnd_year2(0);
  //   } else {
  //     setExpEnd_year2(event);
  //     setError8(false);
  //   }
  //   if (event?.get("year") > dayjs().get("year")) {
  //     setError8(true);
  //   }
  // };

  const handelExpYear5 = (event) => {
    setError5(false);

    if (event == null || event == undefined) {
      setExpStart_year1(0);
    } else {
      setExpStart_year1(event);

      if (event?.get("year") > dayjs().get("year")) {
        setError5(true);
        toast.error("Experience start year cannot be in the future");
      } else if (
        expStart_year2 &&
        event?.get("year") === expStart_year2.get("year")
      ) {
        setError5(true);
        toast.error(
          "Latest and Previous experiences start year cannot be same"
        );
      } else {
        setError5(false); // Clear error if valid
      }

      // Update error for end year if start year changes
      if (expEnd_year1 && event?.get("year") > expEnd_year1.get("year")) {
        setError6(true);
        toast.error("Experience end year cannot be before start year");
      } else {
        setError6(false); // Clear error if valid
      }
    }
  };

  const handelExpYear6 = (event) => {
    setError6(false);

    if (event == null || event == undefined) {
      setExpEnd_year1(0);
    } else {
      setExpEnd_year1(event);

      if (event?.get("year") > dayjs().get("year")) {
        setError6(true);
        toast.error("Experience end year cannot be in the future");
      } else if (
        expStart_year1 &&
        event?.get("year") < expStart_year1.get("year")
      ) {
        setError6(true);
        toast.error("Experience end year cannot be before start year");
      } else if (
        expEnd_year2 &&
        event?.get("year") === expEnd_year2.get("year")
      ) {
        setError6(true);
        toast.error("Latest and Previous experiences end year cannot be same");
      } else {
        setError6(false); // Clear error if valid
      }
    }
  };

  const handelExpYear7 = (event) => {
    setError7(false);

    if (event == null || event == undefined) {
      setExpStart_year2(0);
    } else {
      setExpStart_year2(event);

      if (event?.get("year") > dayjs().get("year")) {
        setError7(true);
        toast.error("Experience start year cannot be in the future");
      } else if (
        expStart_year1 &&
        event?.get("year") === expStart_year1.get("year")
      ) {
        setError7(true);
        toast.error(
          "Previous and Latest experiences start year cannot be same"
        );
      } else {
        setError7(false); // Clear error if valid
      }

      // Update error for end year if start year changes
      if (expEnd_year2 && event?.get("year") > expEnd_year2.get("year")) {
        setError8(true);
        toast.error("Experience end year cannot be before start year");
      } else {
        setError8(false); // Clear error if valid
      }
    }
  };

  const handelExpYear8 = (event) => {
    setError8(false);

    if (event == null || event == undefined) {
      setExpEnd_year2(0);
    } else {
      setExpEnd_year2(event);
      setError8(false);

      if (event?.get("year") > dayjs().get("year")) {
        setError8(true);
        toast.error("Experience end year cannot be in the future");
      } else if (
        expStart_year2 &&
        event?.get("year") < expStart_year2.get("year")
      ) {
        setError8(true);
        toast.error("Experience end year cannot be before start year");
      } else if (
        expEnd_year1 &&
        event?.get("year") === expEnd_year1.get("year")
      ) {
        setError8(true);
        toast.error("Previous and Latest experiences end year cannot be same");
      } else {
        setError8(false); // Clear error if valid
      }
    }
  };

  const handelAchYear9 = (event) => {
    setError9(false);
    if (event == null || event == undefined) {
      setAchived_year1(0);
    } else {
      setAchived_year1(event);
      setError9(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError9(true);
    }
  };

  const handelAchYear10 = (event) => {
    setError9(false);
    if (event == null || event == undefined) {
      setAchived_year2(0);
    } else {
      setAchived_year2(event);
      setError9(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError9(true);
    }
  };
  const handelAwaYear11 = (event) => {
    setError10(false);
    if (event == null || event == undefined) {
      setAwarded_year1(0);
    } else {
      setAwarded_year1(event);
      setError10(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError10(true);
    }
  };

  const handelAwaYear12 = (event) => {
    setError10(false);
    if (event == null || event == undefined) {
      setAwarded_year2(0);
    } else {
      setAwarded_year2(event);
      setError10(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError10(true);
    }
  };

  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePayment1 = () => {
    // Handle payment logic here
    console.log("Payment initiated for plan:", selectedPlan);
  };

  return (
    <Box
      sx={{
        width: "60%",
        padding: "3%",
        marginLeft: "20%",
        "@media screen and (max-width: 768px)": {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
        },
      }}
    >
      <div className="float-right">
        <IconButton
          color="inherit"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Avatar alt="" src={user?.profile_completion?.profile_pic} />{" "}
          <span class="ml-3 text-gray-400 group-hover:text-gray-300">
            <svg
              width="10"
              height="6"
              viewbox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(logOut());
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
          {/*
              <MenuItem onClick={() => {
                dispatch(logOut());
              }} > <p className='px-4'>Logout</p> </MenuItem> */}
        </Menu>
      </div>
      <section class="relative overflow-hidden" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="max-w-2xl mx-auto mb-18 text-center">
              <a class="inline-flex items-center mb-3" href="#">
                <img src={Logo} alt="" />
              </a>
              <h1
                className="font-heading text-5xl md:text-3xl font-semibold text-gray-600 mb-10"
                style={{ fontSize: "28px" }}
              >
                <span>Complete your profile</span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              {activeStep == 0 ? (
                <Box>
                  <div class="flex flex-wrap items-center -mx-4 mb-8">
                    <div class="w-full px-4 mt-2 ">
                      <div>
                      <h1
                      className="font-heading text-5xl md:text-3xl font-semibold text-gray-600 text-center "
                      style={{ fontSize: "22px" }}
                    >
                      <span>Basic Details</span>
                     </h1>
                        <FormControl
                          size="small"
                          fullWidth
                          required
                          margin="normal"
                          variant="outlined"
                        >
                          <InputLabel>Category</InputLabel>
                          <Select
                            required
                            name="category"
                            label="Category"
                            size="small"
                            value={formData.category}
                            onChange={handleFormChange}
                          >
                            {category.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      {formData.category === "Student" && (
                        <div>
                          <Textarea
                            placeholder="Career Objectives"
                            name="career_objectives"
                            value={formData.career_objectives}
                            onChange={handleFormChange}
                            minRows={2}
                            maxRows={4}
                            endDecorator={
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "var(--Textarea-paddingBlock)",
                                  pt: "var(--Textarea-paddingBlock)",
                                  borderTop: "1px solid",
                                  borderColor: "divider",
                                  flex: "auto",
                                }}
                              >
                                <Typography
                                  level="body-xs"
                                  sx={{
                                    ml: "auto",
                                    color:
                                      formData?.career_objectives?.length < 380
                                        ? "black"
                                        : "red",
                                  }}
                                >
                                  {formData?.career_objectives?.length < 380
                                    ? `${formData.career_objectives.length} character(s)`
                                    : characterLimit2}
                                </Typography>
                              </Box>
                            }
                            sx={{
                              minWidth: 500,
                            }}
                          />
                          {/* <FormControl
                            size="small"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          
                          >
                            <TextField
                              id="outlined-multiline-static"
                              label="Career Objective"
                              name= 'career_objectives'
                              value={formData.career_objectives}
                            onChange={handleFormChange}
                              rows={3}
                              multiline
                            />
                          </FormControl> */}
                        </div>
                      )}
                      {formData.category === "Student" && (
                        <div>
                          <FormControl
                            size="small"
                            fullWidth
                            required
                            margin="normal"
                            variant="outlined"
                          >
                            <InputLabel>Sub Category</InputLabel>
                            <Select
                              name="education_level"
                              label="Sub Category"
                              value={formData.education_level}
                              onChange={handleFormChange}
                            >
                              {studentSubcategories.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                      {formData.category === "Student" && (
                        <>
                          <div>
                            <FormControl
                              variant="outlined"
                              required
                              fullWidth
                              size="small"
                            >
                              <InputLabel>Institute name </InputLabel>
                              <OutlinedInput
                                type="text"
                                required
                                label="Institute name"
                                name="institute_name" // Make sure to set the name attribute
                                value={formData.institute_name}
                                onChange={handleFormChange}
                                // You can add more attributes or props as needed
                              />
                            </FormControl>
                          </div>
                          <div style={{ marginTop: "5px" }}>
                            <h6 className="font-semibold text-lg py-2 text-[#0F3D79]">
                              Institute Location
                            </h6>
                          </div>
                        </>
                      )}
                      {formData.category == "" ? (
                        <></>
                      ) : (
                        <>
                          {formData.category === "Student" &&
                          formData.category !== "" ? (
                            <></>
                          ) : (
                            <>
                              <div style={{ marginTop: "5px" }}>
                                <h6 className="font-semibold text-lg py-2 text-[#0F3D79]">
                                  Current Position
                                </h6>
                              </div>

                              <div style={{ marginTop: "10px" }}>
                                <FormControl
                                  variant="outlined"
                                  required
                                  fullWidth
                                  size="small"
                                >
                                  <InputLabel>Designation </InputLabel>
                                  <OutlinedInput
                                    type="text"
                                    required
                                    label="Designation "
                                    name="designation" // Make sure to set the name attribute
                                    value={formData.designation}
                                    onChange={handleFormChange}
                                    // You can add more attributes or props as needed
                                  />
                                </FormControl>
                              </div>

                              <div style={{ marginTop: "10px" }}>
                                <FormControl
                                  variant="outlined"
                                  required
                                  fullWidth
                                  size="small"
                                >
                                  <InputLabel>Company Name</InputLabel>
                                  <OutlinedInput
                                    type="text"
                                    required
                                    label="Company Name"
                                    name="company_name" // Make sure to set the name attribute
                                    value={formData.company_name}
                                    onChange={handleFormChange}
                                    // You can add more attributes or props as needed
                                  />
                                </FormControl>
                              </div>

                              <div style={{ marginTop: "5px" }}>
                                <h6 className="font-semibold text-lg py-2 text-[#0F3D79]">
                                  Company Location
                                </h6>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <div style={{ marginTop: "10px" }}>
                        <FormControl
                          variant="outlined"
                          required
                          fullWidth
                          size="small"
                        >
                          <InputLabel>City</InputLabel>
                          <OutlinedInput
                            type="text"
                            required
                            label="City"
                            name="city"
                            value={formData.city}
                            onChange={handleFormChange}
                          />
                        </FormControl>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <FormControl
                          variant="outlined"
                          required
                          fullWidth
                          size="small"
                        >
                          <InputLabel>State</InputLabel>
                          <OutlinedInput
                            type="text"
                            required
                            label="State"
                            name="state"
                            value={formData.state}
                            onChange={handleFormChange}
                          />
                        </FormControl>
                      </div>

                      <div style={{ marginTop: "5px" }}>
                        <h6 className="font-semibold text-lg py-2 text-[#0F3D79]">
                          Interested Domains
                        </h6>
                      </div>
                      <div>
                        {visible ? (
                          <Autocomplete
                            multiple
                            // style={{height:'20px'}}
                            id="checkboxes-tags-demo"
                            options={Keyareas}
                            disableCloseOnSelect
                            name={(option) => option?.title}
                            getOptionLabel={(option) => option?.title}
                            renderOption={(props, option, { selected }) => {
                              const isOptionSelected = (
                                selectedkeyArea || []
                              ).some(
                                (defaultOption) =>
                                  defaultOption.title === option.title
                              );
                              return (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 2 }}
                                    checked={selected || isOptionSelected}
                                  />
                                  {option.title}
                                </li>
                              );
                            }}
                            value={selectedkeyArea}
                            onChange={(event, newValue) =>
                              handleKeyAreaSelection(newValue)
                            }
                            style={{ width: "100%", marginTop: "10px" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Interested Domains"
                                variant="outlined"
                                required={skills.length > 0 ? false : true}
                              />
                            )}
                          />
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="py-5">
                        <label>
                          <Button
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            variant="outlined"
                            color="neutral"
                          >
                            {document ? (
                              <>
                                <img src={document} className="h-36 w-36 " />
                                <button
                                  type="button"
                                  onClick={handleDeleteClickLogo}
                                >
                                  <DeleteIcon />
                                </button>
                              </>
                            ) : (
                              "Upload your profile picture"
                            )}
                            <VisuallyHiddenInput
                              type="file"
                              accept="image/*"
                              onChange={handleFileInputChange}
                            />
                          </Button>
                        </label>
                        <>
                          {profilePic < 100 && !document ? (
                            <span
                              className="ml-5 font-semibold"
                              style={{
                                color: profilePic < 100 ? "red" : "green",
                              }}
                            >
                              Upload {profilePic}%
                            </span>
                          ) : (
                            <span
                              className="ml-5 font-semibold"
                              style={{ color: "green" }}
                            >
                              {uploadSuccessMsg}
                            </span>
                          )}
                        </>
                      </div>

                    </div>
                  </div>
                </Box>
              ) : activeStep == 1 ? (
                <Box>
                  <div class="w-full  px-4 mt-6 ">
                  <h1
                      className="font-heading text-5xl md:text-3xl font-semibold text-gray-600 text-center "
                      style={{ fontSize: "22px" }}
                    >
                      <span>Education Details</span>
                     </h1>
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-5">
                      Latest Education
                    </h2>

                    <div style={{ marginTop: "10px" }}>
                      
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Institution name </InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Institution name "
                          name="institute_name1" // Make sure to set the name attribute
                          value={eduForm.institute_name1}
                          onChange={handelEduFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="normal"
                        style={{ marginTop: "10px" }}
                      >
                        <InputLabel>Course selection</InputLabel>
                        <Select
                          required
                          name="education_level1"
                          label="Course Selection"
                          size="small"
                          value={eduForm.education_level1}
                          onChange={handelEduFormChange}
                        >
                          {subCategory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div style={{ marginTop: "5px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Course name </InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Course name"
                          name="course_name1" // Make sure to set the name attribute
                          value={eduForm.course_name1}
                          onChange={handelEduFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div className="mt-3">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              required
                            >
                              <DatePicker
                                name="start_year1"
                                slotProps={{
                                  textField: { size: "small", error: error1 },
                                }}
                                label="Start year"
                                views={["year"]}
                                value={dayjs(start_year1)}
                                onChange={handelEduYear1}
                                shouldDisableYear={isInCurrentYear}
                              />
                              {error1 && (
                                <FormHelperText error={error1}>
                                  Invalid year
                                </FormHelperText>
                              )}
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              required
                            >
                              <DatePicker
                                name="end_year1"
                                slotProps={{
                                  textField: { size: "small", error: error2 },
                                }}
                                label="End year"
                                views={["year"]}
                                value={dayjs(end_year1)}
                                onChange={handelEduYear2}
                                shouldDisableYear={isInCurrentYear}
                              />
                              {error2 && (
                                <FormHelperText error={error2}>
                                  Invalid year
                                </FormHelperText>
                              )}
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div class="w-full  px-4 mt-6 ">
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-1">
                      Previous Education
                    </h2>

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Institution name </InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Institution name "
                          name="institute_name2" // Make sure to set the name attribute
                          value={eduForm.institute_name2}
                          onChange={handelEduFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="normal"
                        style={{ marginTop: "10px" }}
                      >
                        <InputLabel>Course selection</InputLabel>
                        <Select
                          required
                          name="education_level2"
                          label="Course Selection"
                          size="small"
                          value={eduForm.education_level2}
                          onChange={handelEduFormChange}
                        >
                          {subCategory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div style={{ marginTop: "5px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Course name </InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Course name"
                          name="course_name2" // Make sure to set the name attribute
                          value={eduForm.course_name2}
                          onChange={handelEduFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div className="mt-3">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              required
                            >
                              <DatePicker
                                name="start_year2"
                                slotProps={{
                                  textField: { size: "small", error: error3 },
                                }}
                                label="Start year"
                                views={["year"]}
                                value={dayjs(start_year2)}
                                onChange={handelEduYear3}
                                shouldDisableYear={isInCurrentYear}
                              />
                              {error3 && (
                                <FormHelperText error={error3}>
                                  Invalid year
                                </FormHelperText>
                              )}
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              required
                            >
                              <DatePicker
                                name="end_year2"
                                slotProps={{
                                  textField: { size: "small", error: error4 },
                                }}
                                label="End year"
                                views={["year"]}
                                value={dayjs(end_year2)}
                                onChange={handelEduYear4}
                                shouldDisableYear={isInCurrentYear}
                              />
                              {error4 && (
                                <FormHelperText error={error4}>
                                  Invalid year
                                </FormHelperText>
                              )}
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Box>
              ) : activeStep == 2 ? (
                <>
                  <div class="w-full  px-4 mt-6 ">
                  <h1
                      className="font-heading text-5xl md:text-3xl font-semibold text-gray-600 text-center "
                      style={{ fontSize: "22px" }}
                    >
                      <span>Work Experience Details</span>
                     </h1>
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-5">
                      Latest Experience
                    </h2>

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Company name</InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Company name"
                          name="company_name1" // Make sure to set the name attribute
                          value={expForm.company_name1}
                          onChange={handleExpFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Designation</InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Designation"
                          name="designation1" // Make sure to set the name attribute
                          value={expForm.designation1}
                          onChange={handleExpFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Location </InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Location "
                          name="company_location1" // Make sure to set the name attribute
                          value={expForm.company_location1}
                          onChange={handleExpFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div className="mt-3">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                name="expStart_year1"
                                slotProps={{
                                  textField: { size: "small", error: error5 },
                                }}
                                label="Start year"
                                views={["year"]}
                                value={dayjs(expStart_year1)}
                                onChange={handelExpYear5}
                                shouldDisableYear={isInCurrentYear}
                              />
                              {error5 && (
                                <FormHelperText error={error5}>
                                  Invalid year
                                </FormHelperText>
                              )}
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                name="expEnd_year1"
                                slotProps={{
                                  textField: { size: "small", error: error6 },
                                }}
                                label="End year"
                                views={["year"]}
                                value={dayjs(expEnd_year1)}
                                onChange={handelExpYear6}
                                shouldDisableYear={isInCurrentYear}
                              />
                              {error6 && (
                                <FormHelperText error={error6}>
                                  Invalid year
                                </FormHelperText>
                              )}
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </div>{" "}
                  <div class="w-full  px-4 mt-6 ">
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-1">
                      Previous Experience
                    </h2>

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Company name</InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Company name"
                          name="company_name2" // Make sure to set the name attribute
                          value={expForm.company_name2}
                          onChange={handleExpFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Designation</InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Designation"
                          name="designation2" // Make sure to set the name attribute
                          value={expForm.designation2}
                          onChange={handleExpFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Location </InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Location "
                          name="company_location2" // Make sure to set the name attribute
                          value={expForm.company_location2}
                          onChange={handleExpFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div>

                    <div className="mt-3">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                name="expStart_year2"
                                slotProps={{
                                  textField: { size: "small", error: error7 },
                                }}
                                label="Start year"
                                views={["year"]}
                                value={dayjs(expStart_year2)}
                                onChange={handelExpYear7}
                                shouldDisableYear={isInCurrentYear}
                              />
                              {error7 && (
                                <FormHelperText error={error7}>
                                  Invalid year
                                </FormHelperText>
                              )}
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                name="expStart_year2"
                                slotProps={{
                                  textField: { size: "small", error: error8 },
                                }}
                                label="End year"
                                views={["year"]}
                                value={dayjs(expEnd_year2)}
                                onChange={handelExpYear8}
                                shouldDisableYear={isInCurrentYear}
                              />
                              {error8 && (
                                <FormHelperText error={error8}>
                                  Invalid year
                                </FormHelperText>
                              )}
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </>
              ) : activeStep == 3 ? (
                <>
                  <div class="w-full  px-4 mt-6 ">
                  <h1
                      className="font-heading text-5xl md:text-3xl font-semibold text-gray-600 text-center "
                      style={{ fontSize: "22px" }}
                    >
                      <span>Achievements and Awards</span>
                     </h1>
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-5">
                      Details
                    </h2>

                    <Textarea
                      placeholder="Description"
                      value={awardForm.award1}
                      name="award1"
                      onChange={handleAwardFormChange}
                      minRows={2}
                      maxRows={4}
                      endDecorator={
                        <Box
                          sx={{
                            display: "flex",
                            gap: "var(--Textarea-paddingBlock)",
                            pt: "var(--Textarea-paddingBlock)",
                            borderTop: "1px solid",
                            borderColor: "divider",
                            flex: "auto",
                          }}
                        >
                          <Typography
                            level="body-xs"
                            sx={{
                              ml: "auto",
                              color:
                                awardForm.award1.length < 160 ? "black" : "red",
                            }}
                          >
                            {awardForm.award1.length < 160
                              ? `${awardForm.award1.length} character(s)`
                              : characterLimit}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        minWidth: 350,
                      }}
                    />

                    {/* <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Award name</InputLabel>
                        <OutlinedInput
                          type="text"
                          required
                          label="Award name"
                          name="award1" // Make sure to set the name attribute
                          value={awardForm.award1}
                          onChange={handleAwardFormChange}
                          // You can add more attributes or props as needed
                        />
                      </FormControl>
                    </div> */}

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="awarded_year1"
                            slotProps={{
                              textField: { size: "small", error: error9 },
                            }}
                            label="Year of Accomplishment"
                            views={["year"]}
                            value={dayjs(awarded_year1)}
                            onChange={handelAwaYear11}
                            shouldDisableYear={isInCurrentYear}
                          />
                          {error9 && (
                            <FormHelperText error={error9}>
                              Invalid year
                            </FormHelperText>
                          )}
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                  </div>

                  <div class="w-full  px-4 mt-6 ">
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-5">
                    Details
                    </h2>

                    <Textarea
                      placeholder="Description"
                      value={awardForm.award2}
                      name="award2"
                      onChange={handleAwardFormChange}
                      minRows={2}
                      maxRows={4}
                      endDecorator={
                        <Box
                          sx={{
                            display: "flex",
                            gap: "var(--Textarea-paddingBlock)",
                            pt: "var(--Textarea-paddingBlock)",
                            borderTop: "1px solid",
                            borderColor: "divider",
                            flex: "auto",
                          }}
                        >
                          <Typography
                            level="body-xs"
                            sx={{
                              ml: "auto",
                              color:
                                awardForm.award2.length < 160 ? "black" : "red",
                            }}
                          >
                            {awardForm.award2.length < 160
                              ? `${awardForm.award2.length} character(s)`
                              : characterLimit}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        minWidth: 350,
                      }}
                    />

                    {/* <div style={{ marginTop: "10px" }}>
                 <FormControl variant="outlined" fullWidth size="small">
                   <InputLabel>Award name</InputLabel>
                   <OutlinedInput
                     type="text"
                     required
                     label="Award name"
                     name="award1" // Make sure to set the name attribute
                     value={awardForm.award1}
                     onChange={handleAwardFormChange}
                     // You can add more attributes or props as needed
                   />
                 </FormControl>
               </div> */}

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="awarded_year2"
                            slotProps={{
                              textField: { size: "small", error: error9 },
                            }}
                            label="Year of Accomplishment."
                            views={["year"]}
                            value={dayjs(awarded_year2)}
                            onChange={handelAwaYear12}
                            shouldDisableYear={isInCurrentYear}
                          />
                          {error9 && (
                            <FormHelperText error={error9}>
                              Invalid year
                            </FormHelperText>
                          )}
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                  </div>

                  {/* <div class="w-full px-4 mt-6 ">
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-5">
                      Achievements 1
                    </h2>

                    <Textarea
                      placeholder="Achievement Name"
                      name="achivement1"
                      value={awardForm.achivement1}
                      onChange={handleAwardFormChange}
                      minRows={2}
                      maxRows={4}
                      endDecorator={
                        <Box
                          sx={{
                            display: "flex",
                            gap: "var(--Textarea-paddingBlock)",
                            pt: "var(--Textarea-paddingBlock)",
                            borderTop: "1px solid",
                            borderColor: "divider",
                            flex: "auto",
                          }}
                        >
                          <Typography
                            level="body-xs"
                            sx={{
                              ml: "auto",
                              color:
                                awardForm.achivement1.length < 500
                                  ? "black"
                                  : "red",
                            }}
                          >
                            {awardForm.achivement1?.length < 500
                              ? `${awardForm.achivement1.length} character(s)`
                              : characterLimit1}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        minWidth: 350,
                      }}
                    />

            
                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="achived_year1"
                            slotProps={{
                              textField: { size: "small", error: error10 },
                            }}
                            label="Year of Achievement"
                            views={["year"]}
                            value={dayjs(achived_year1)}
                            onChange={handelAchYear9}
                            shouldDisableYear={isInCurrentYear}
                          />
                          {error10 && (
                            <FormHelperText error={error10}>
                              Invalid year
                            </FormHelperText>
                          )}
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                  </div> */}

                  {/* <div class="w-full  px-4 mt-6 ">
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-5">
                      Achievements 2
                    </h2>

                    <Textarea
                      placeholder="Achievement Name"
                      name="achivement2"
                      value={awardForm.achivement2}
                      onChange={handleAwardFormChange}
                      minRows={2}
                      maxRows={4}
                      endDecorator={
                        <Box
                          sx={{
                            display: "flex",
                            gap: "var(--Textarea-paddingBlock)",
                            pt: "var(--Textarea-paddingBlock)",
                            borderTop: "1px solid",
                            borderColor: "divider",
                            flex: "auto",
                          }}
                        >
                          <Typography
                            level="body-xs"
                            sx={{
                              ml: "auto",
                              color:
                                awardForm.achivement2.length < 500
                                  ? "black"
                                  : "red",
                            }}
                          >
                            {awardForm.achivement2?.length < 500
                              ? `${awardForm.achivement2.length} character(s)`
                              : characterLimit1}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        minWidth: 350,
                      }}
                    />

                

                    <div style={{ marginTop: "10px" }}>
                      <FormControl variant="outlined" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="achived_year1"
                            slotProps={{
                              textField: { size: "small", error: error10 },
                            }}
                            label="Year of Achievement"
                            views={["year"]}
                            value={dayjs(achived_year2)}
                            onChange={handelAchYear10}
                            shouldDisableYear={isInCurrentYear}
                          />
                          {error10 && (
                            <FormHelperText error={error10}>
                              Invalid year
                            </FormHelperText>
                          )}
                        </LocalizationProvider>
                      </FormControl>
                    </div>
                  </div> */}
                </>
              ) : activeStep == 4 ? (
                <>
                  <div class="w-full  px-4 mt-6 ">
                  <h1
                      className="font-heading text-5xl md:text-3xl font-semibold text-gray-600 text-center "
                      style={{ fontSize: "22px" }}
                    >
                      <span>Skills</span>
                     </h1>
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-5">
                      Add Skills
                    </h2>

                    <input
                      type="text"
                      placeholder="Skill Name"
                      value={awardForm.skill}
                      name="skill"
                      onChange={handleAwardFormChange}
                      className="border p-2 rounded mb-5 w-full"
                    />

                    <Textarea
                      placeholder="Details"
                      value={awardForm.award1}
                      name="award1"
                      onChange={handleAwardFormChange}
                      minRows={2}
                      maxRows={4}
                      endDecorator={
                        <Box
                          sx={{
                            display: "flex",
                            gap: "var(--Textarea-paddingBlock)",
                            pt: "var(--Textarea-paddingBlock)",
                            borderTop: "1px solid",
                            borderColor: "divider",
                            flex: "auto",
                          }}
                        >
                          <Typography
                            level="body-xs"
                            sx={{
                              ml: "auto",
                              color:
                                awardForm.award1.length < 160 ? "black" : "red",
                            }}
                          >
                            {awardForm.award1.length < 160
                              ? `${awardForm.award1.length} character(s)`
                              : characterLimit}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        minWidth: 350,
                      }}
                    />

                  </div>

                  <div class="w-full px-4 mt-6">
                    <h2 class="text-xl font-semibold text-[#0F3D79] mb-5">
                      Add Skills
                    </h2>

                    <input
                      type="text"
                      placeholder="Skill Name"
                      value={awardForm.skill}
                      name="skill"
                      onChange={handleAwardFormChange}
                      className="border p-2 rounded mb-5 w-full"
                    />

                    <Textarea
                      placeholder="Details"
                      value={awardForm.award2}
                      name="award2"
                      onChange={handleAwardFormChange}
                      minRows={2}
                      maxRows={4}
                      endDecorator={
                        <Box
                          sx={{
                            display: "flex",
                            gap: "var(--Textarea-paddingBlock)",
                            pt: "var(--Textarea-paddingBlock)",
                            borderTop: "1px solid",
                            borderColor: "divider",
                            flex: "auto",
                          }}
                        >
                          <Typography
                            level="body-xs"
                            sx={{
                              ml: "auto",
                              color: awardForm.award2.length < 160 ? "black" : "red",
                            }}
                          >
                            {awardForm.award2.length < 160
                              ? `${awardForm.award2.length} character(s)`
                              : characterLimit}
                          </Typography>
                        </Box>
                      }
                      sx={{
                        minWidth: 350,
                      }}
                    />
                  </div>


                
                </>
              )
        
              : activeStep == 5 ? (
                <>
                  <div className="w-full sm:w-full mt-6 ml-1">
                  <h1
                      className="font-heading text-5xl md:text-3xl font-semibold text-gray-600 text-center "
                      style={{ fontSize: "22px" }}
                    >
                      <span>Activate Your Subscription</span>
                     </h1>
                    <h3
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        marginBottom: "10px",
                        color: "#0F3D79",
                      }}
                    >
                      Welcome! You can start enjoying full access now or explore
                      our free trial. Choose below:
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        style={{
                          flexBasis: "45%",
                          padding: "20px",
                          backgroundColor: "#F3F4F6",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        <div>
                          <h4
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              marginBottom: "10px",
                            }}
                          >
                            Start Free Period
                          </h4>
                          <p style={{ fontSize: "14px", marginBottom: "20px" }}>
                            Enjoy 7 days of full access for free. Remember, you
                            must subscribe within 7 days to continue using all
                            features after the free period ends.
                          </p>
                        </div>

                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#FFFFFF",
                            color: "#1976D2",
                            border: "1px,solid",
                            borderRadius: "5px",
                            cursor: "pointer",
                            marginBottom: "10px",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            nav("/home");
                          }}
                        >
                          Skip & Use Free Period
                        </button>
                      </div>
                      <div
                        style={{
                          flexBasis: "45%",
                          padding: "20px",
                          backgroundColor: "#F3F4F6",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          textAlign: "center",
                        }}
                      >
                        <div>
                          <h4
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              marginBottom: "10px",
                            }}
                          >
                            Subscribe Now
                          </h4>
                          <p style={{ fontSize: "14px", marginBottom: "20px" }}>
                            Start your subscription today and enjoy
                            uninterrupted access starting from today.
                          </p>
                        </div>

                        <button
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#1976D2",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            marginBottom: "10px",
                          }}
                          onClick={() => handlePayment()}
                        >
                          Select Plan 
                        </button>
                      </div>
                    </div>
                  </div>
                </>



) :  (
  <div className="w-full sm:w-full mt-6 ml-1">
                     <h1
                      className="font-heading text-5xl md:text-3xl font-semibold text-gray-600 text-center "
                      style={{ fontSize: "22px" }}
                    >
                      <span>Select Plan</span>
                     </h1>
  <h3
    style={{
      fontSize: "16px",
      fontWeight: "600",
      marginBottom: "20px",
      color: "#0F3D79",
      textAlign: "center",
    }}
  >
    Select Subscription Type
  </h3>

  <div
    style={{
      backgroundColor: "#F3F4F6",
      borderRadius: "10px",
      padding: "20px",
      marginBottom: "20px",
    }}
  >
 <table style={{ width: "100%", textAlign: "center", borderCollapse: "separate", borderSpacing: "0 10px" }}>
  <thead>
    <tr style={{ borderBottom: "2px solid #ddd" }}>
      <th>Amount</th>
      <th>Billing Period</th>
      <th>Discount</th>
      <th>Select</th>
    </tr>
  </thead>
  <tbody>
    <tr style={{ backgroundColor: selectedPlan === 1 ? "#E0F7FA" : "transparent" }}>
      <td>₹125</td>
      <td>1 Month</td>
      <td>NIL</td>
      <td>
        <input
          type="checkbox"
          checked={selectedPlan === 1}
          onChange={() => setSelectedPlan(1)}
        />
      </td>
    </tr>
    <tr style={{ backgroundColor: selectedPlan === 2 ? "#E0F7FA" : "transparent" }}>
      <td>₹750</td>
      <td>1 Year</td>
      <td>50%</td>
      <td>
        <input
          type="checkbox"
          checked={selectedPlan === 2}
          onChange={() => setSelectedPlan(2)}
        />
      </td>
    </tr>
  </tbody>
</table>

    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <button
        style={{
          padding: "10px 20px",
          backgroundColor: "#1976D2",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontWeight: "600",
        }}
        onClick={handlePayment1}
        disabled={!selectedPlan}
      >
        Make Payment
      </button>
    </div>
  </div>

  <div style={{ fontSize: "12px", marginTop: "10px", color: "#555" }}>
    <p><strong>Note:</strong></p>
    <ul style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
      <li>No refund</li>
      <li>10 days trial period for monthly subscription</li>
      <li>30 days trial period for annual subscription</li>
      <li>Offer valid for one month</li>
    </ul>
  </div>
</div>



              )}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button> */}
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: "inline-block" }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={() => handleComplete()}>
                    {completedSteps() === totalSteps() - 1
                      ? "Finish"
                      : "Complete Step"}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
