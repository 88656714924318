import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import { Typography } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { keyUri } from "../../key";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [token, setToken] = useState("");
  const [rules, setRules] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowPassword1 = () => setShowPassword1((prev) => !prev);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlSearchParams.get("token"); // Use get instead of getItem
 

    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, []);

  const content = {
    brand: { image: { Logo }, width: 120 },
    "02_secondary-action": "Don't have an account? Sign up here",
    "02_tertiary-action": "Forgot password?",
  };

  const brand = content.brand.image.Logo ? (
    <img src={content.brand.image.Logo} alt="" width={content.brand.width} />
  ) : (
    content.brand.text || ""
  );

  const isPasswordValid = (password) => {
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const numbercaseRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    const regex = /\s/;

    return (
      password.length >= minLength &&
      uppercaseRegex.test(password) &&
      specialCharRegex.test(password) &&
      numbercaseRegex.test(password) &&
      !regex.test(password)
    );
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      const result = isPasswordValid(value);
      setRules(
        result
          ? ""
          : "Password must be at least 8 characters long. Use a minimum of 1 special character, 1 number, and 1 uppercase letter without any spaces."
      );
    }

    if (name === "password" && password.confirmPassword !== "") {
      setPassword((prev) => ({ ...prev, confirmPassword: "" }));
      setPasswordMismatch(false);
    }

    if (name === "confirmPassword") {
      setPasswordMismatch(password.password !== value);
    }

    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onFinish = async (e) => {
    e.preventDefault();

    if (
      password.password === password.confirmPassword &&
      !passwordMismatch &&
      rules === "" &&
      password.password !== ""
    ) {
      if (token) {
        try {
          const { data } = await axios.post(
            `${keyUri.BACKEND_URI}/users/reset-password?token=${token}`,
            { password: password.password }
          );

          // Navigate to home on success
          navigate("/");
          // Uncomment this to show success message
          // toast.success(`${data?.message}`, { position: "top-center", autoClose: 3000, hideProgressBar: true });
        } catch (error) {
         

          // Determine the error message based on the response status
          if (error.response) {
            const { status, data } = error.response;

            // Display the message from the backend response directly
            toast.error(
              data.message ||
                "An unexpected error occurred. Please try again later.",
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
              }
            );
          } else {
            // Handle network or other errors
            toast.error("Network error. Please check your connection.", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
            });
          }

          // Navigate back to the forgot password page after a delay
          setTimeout(() => {
            navigate("/forgot-password");
          }, 3000);
        }
      } else {
        toast.warning("No reset token provided. Please request a new link.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    } else {
      toast.warning(
        "Please ensure your passwords match and meet the requirements.",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
        }
      );
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <Box className="">
        <Container maxWidth="xs">
          <form onSubmit={onFinish}>
            <Box pt={8} pb={10}>
              <Box mb={1} textAlign="center">
                <div
                  style={{
                    marginLeft: "33%",
                    marginBottom: "5%",
                    marginTop: "30%",
                  }}
                >
                  <Link href="/" variant="h4" color="inherit" underline="none">
                    {brand}
                  </Link>
                </div>
                <Box textAlign="center">
                  <Typography
                    variant="h5"
                    component="h2"
                    className="text-center text-[#0F3D79]"
                  >
                    Reset Password
                  </Typography>
                </Box>
              </Box>

              <div>
                <FormControl
                  fullWidth
                  sx={{ width: "100%", marginTop: "10px" }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    value={password.password}
                    error={Boolean(rules !== "")}
                  />
                  <FormHelperText
                    id="outlined-password-helper-text"
                    style={{ color: "red" }}
                  >
                    {rules}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ width: "100%", marginTop: "10px" }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-confirm-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm-password"
                    name="confirmPassword"
                    type={showPassword1 ? "text" : "password"}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                    value={password.confirmPassword}
                    error={Boolean(passwordMismatch)}
                  />
                  <FormHelperText
                    id="outlined-confirm-password-helper-text"
                    style={{ color: "red" }}
                  >
                    {passwordMismatch ? "Passwords do not match" : ""}
                  </FormHelperText>
                </FormControl>

                <Stack direction="row" spacing={0} my={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      fontSize: "12px",
                      backgroundColor: "#0066A2",
                      color: "white",
                      marginLeft: "60%",
                    }}
                  >
                    Reset Password
                  </Button>
                </Stack>
              </div>
            </Box>
          </form>
        </Container>
      </Box>
      <div className="border-t border-gray-200 mt-40 md:mt-80">
        <div className="lg:flex items-center py-3 float-right md:mr-60">
          <div className="flex lg:mb-0 items-center">
            <a
              className="inline-block mr-4 sm:mr-10 text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
              target="_blank"
              rel="noopener noreferrer"
              href="/privacy"
            >
              Privacy Policy
            </a>
            <a
              className="inline-block mr-4 sm:mr-10 text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
              target="_blank"
              rel="noopener noreferrer"
              href="/agreement"
            >
              User Agreement
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
