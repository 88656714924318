import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";
import { fetchIndividualProfile } from "../api/individualUser";

const token = localStorage.getItem("token") || null;

export const initialState = {
  loading: false,
  hasErrors: false,
  topics: [],
  currentTopic: {},
  individualtoken: token,
  topicStatus: {}, // To store status of each topic
};

export const topicSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {
    getTopicLoading: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    getAllTopicsSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.topics = payload?.all_topics || [];
      state.hasErrors = false;
    },
    getCurrentTopicSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.currentTopic = payload?.topic || {};
      state.hasErrors = false;
    },
    getCurrentDeleteTopicSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.currentTopic = {};
      state.hasErrors = false;
    },
    getTopicFailed: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    updateTopicStatus: (state, { payload }) => {
      const { id, status } = payload;
      state.topicStatus[id] = status;
    },
  },
});

export const {
  getTopicLoading,
  getAllTopicsSuccessfull,
  getCurrentTopicSuccessfull,
  getCurrentDeleteTopicSuccessfull,
  getTopicFailed,
  updateTopicStatus,
} = topicSlice.actions;

export const topicSelector = (state) => state.topics;

export default topicSlice.reducer;

// Thunks

export const fetchAllTopics = () => async (dispatch) => {
  dispatch(getTopicLoading());
  try {
    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/individual-users/fetch-topic`,
      config
    );
    dispatch(getAllTopicsSuccessfull(data));
  } catch (error) {
    dispatch(getTopicFailed());
  }
};

export const fetchOneTopic = (id) => async (dispatch) => {
  dispatch(getTopicLoading());
  try {
    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/individual-users/get-a-topic/${id}`,
      config
    );
    dispatch(getCurrentTopicSuccessfull(data));
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to fetch topic.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getTopicFailed());
  }
};

export const createTopics = (value) => async (dispatch) => {
  dispatch(getTopicLoading());
  try {
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/individual-users/create-topic`,
      value,
      config
    );
    dispatch(fetchIndividualProfile(token));
  } catch (error) {
    dispatch(getTopicFailed());
  }
};

export const deleteTopic = (id) => async (dispatch) => {
  dispatch(getTopicLoading());
  try {
    const { data } = await axios.delete(
      `${keyUri.BACKEND_URI}/individual-users/delete-a-topic/${id}`,
      config
    );
    dispatch(fetchAllTopics());
    toast.success(data.message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to delete topic.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getTopicFailed());
  }
};

export const saveTopic = (userId, topicId, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/individual-users/save-a-topic/${userId}`, // Use topicId in the URL
      { topicId }, // Send userId in the request body
      config // Authorization token in headers
    );

    // Dispatch actions after saving the topic
    dispatch(fetchAllTopics()); // Fetch updated topics
    dispatch(fetchIndividualProfile(token)); // Fetch updated profile

    // Show success toast notification
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    // Show error toast notification
    toast.warning(`${error.response?.data?.message || "Error saving topic"}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const unSaveTopic = (userId, topicId, token) => async (dispatch) => {
  try {
    const loginConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.put(
      keyUri.BACKEND_URI + `/individual-users/unsave-a-topic/${userId}`,
      { topicId },
      loginConfig
    );
    dispatch(fetchAllTopics());

    dispatch(fetchIndividualProfile(token));

    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
