import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@mui/material/Modal";
import { ModalDialog, Textarea } from "@mui/joy";
import { useDispatch } from "react-redux";
import { updateIndividual } from "../../api/individualUser";
import Button from "@mui/joy/Button";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Box, FormHelperText, Typography } from "@mui/material";
import { toast } from "react-toastify";

function Awards({ user, individualtoken }) {
  const dispatch = useDispatch();
  const [open4, setOpen4] = useState(false);
  const [awarded_year1, setAwarded_year1] = useState(null);
  const [awarded_year2, setAwarded_year2] = useState(null);
  const [awards, setAwards] = useState({
    award1: "",
  });
  const [awardsec, setAwardSec] = useState({
    award2: "",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    preFill();
  }, [user]);

  const isInCurrentYear = (date) => date.get("year") > dayjs().get("year");

  const preFill = () => {
    setAwards({
      award1: user?.profile_completion?.award_details_1?.award1
        ? user?.profile_completion?.award_details_1?.award1
        : "",
    });
    setAwardSec({
      award2: user?.profile_completion?.award_details_2?.award2
        ? user?.profile_completion?.award_details_2?.award2
        : "",
    });
    setAwarded_year1(
      user?.profile_completion?.award_details_1?.awarded_year1
        ? user?.profile_completion?.award_details_1?.awarded_year1
        : null
    );

    setAwarded_year2(
      user?.profile_completion?.award_details_2?.awarded_year2
        ? user?.profile_completion?.award_details_2?.awarded_year2
        : null
    );
  };

  const handelClose4 = () => {
    setOpen4(false);
    setError(false);
    preFill();
  };

  //award change handel function

  const handelAwardsChange = (event) => {
    const { name, value } = event.target;
    if (value.length <= 200) {
      setAwards({ award1: value });
    }
    // setAwards((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));
  };

  const handelAwardsChangeSec = (event) => {
    const { name, value } = event.target;
    if (value.length <= 200) {
      setAwardSec({ award2: value });
    }
    // setAwards((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));
  };

  const [characterLimit, setCharacterLimit] = useState(
    "Your description cannot exceed 200 characters and above."
  );

  //award submit handler

  const awardSumbit = (e) => {
    const { award1 } = awards;
    const { award2 } = awardsec;

    // Check for empty awards
    if (!award1 && !award2) {
      toast.warning("Please provide at least one award detail", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (error) {
      toast.warning("Please fill valid year", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      e.preventDefault();

      const award_details = {
        award_details_1: {
          award1,
          awarded_year1,
        },
        award_details_2: {
          award2,
          awarded_year2,
        },
      };
      dispatch(updateIndividual(user?._id, { award_details }, individualtoken));
      handelClose4();
    }
  };

  // const awardSumbit = (e) => {
  //   e.preventDefault();

  //   const { award1, awarded_year1 } = awards;
  //   const { award2, awarded_year2 } = awardsec;

  //   // Check for empty awards
  //   if (!award1 && !award2) {
  //     toast.warning('Please provide at least one award detail', {
  //       position: "top-center",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeButton: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   }

  //   // Check for valid years if provided
  //   if (error) {
  //     toast.warning('Please fill valid year', {
  //       position: "top-center",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeButton: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     return;
  //   }

  //   const award_details = {
  //     award_details_1: {
  //       award1: award1 || '', // Ensure keys are present even if empty
  //       awarded_year1: awarded_year1 || '',
  //     },
  //     award_details_2: {
  //       award2: award2 || '',
  //       awarded_year2: awarded_year2 || '',
  //     }
  //   };

  //   dispatch(updateIndividual(user?._id, { award_details }, individualtoken));
  //   handelClose4();
  // };

  //date picker functions
  const handelAwaYear1 = (event) => {
    setError(false);
    if (event == null || event == undefined) {
      setAwarded_year1(0);
    } else {
      setAwarded_year1(event);

      setError(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError(true);
    }
  };

  const handelAwaYear2 = (event) => {
    setError(false);
    if (event == null || event == undefined) {
      setAwarded_year2(0);
    } else {
      setAwarded_year2(event);

      setError(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError(true);
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div class=" mb-5 p-4 bg-gray-100 rounded-xl  h-[365px]">
        <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
          <div class="w-auto px-4">
            <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">Skills</h3>
          </div>
          {user?.profile_completion?.award_details_1?.award1 == "" ? (
            <button class="w-auto px-2 mb-4 text-gray-500">
              <AddIcon onClick={() => setOpen4(true)} />
            </button>
          ) : (
            <button class="w-auto px-2 mb-4 text-gray-300 hover:text-gray-500">
              <EditIcon onClick={() => setOpen4(true)} />
            </button>
          )}
        </div>

        <div className="mt-2">
          {/* <h4 class="text-lg text-gray-500 font-medium">Award Name :</h4> */}
          <p class="text-md font-semibold text-gray-800 py-2">
            {user?.profile_completion?.award_details_1?.award1}
          </p>
        </div>
        <div className=" flex mt-1">
          {user?.profile_completion?.award_details_1?.awarded_year1 ? (
            <h4 class="text-md  text-[#0F3D79] font-semibold"> Year :</h4>
          ) : null}
          <p class="ml-2 text-md font-semibold text-gray-800">
            {user?.profile_completion?.award_details_1?.awarded_year1
              ? dayjs(
                  user?.profile_completion?.award_details_1?.awarded_year1
                ).format("YYYY")
              : ""}
          </p>
        </div>

        <div className="mt-2">
          {/* <h4 class="text-lg text-gray-500 font-medium">Award Name :</h4> */}
          <p class="text-md font-semibold text-gray-800 py-2">
            {user?.profile_completion?.award_details_2?.award2}
          </p>
        </div>
        <div className=" flex mt-1">
          {user?.profile_completion?.award_details_2?.awarded_year2 ? (
            <h4 class="text-md  text-[#0F3D79] font-semibold"> Year :</h4>
          ) : null}
          <p class="ml-2 text-md font-semibold text-gray-800">
            {user?.profile_completion?.award_details_2?.awarded_year2
              ? dayjs(
                  user?.profile_completion?.award_details_2?.awarded_year2
                ).format("YYYY")
              : ""}
          </p>
        </div>
      </div>
      {/* ------------------------Awards-------------------------------------------- */}

      <>
        <Modal open={open4}>
          <ModalDialog
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            // sx={(theme) => ({
            //   [theme.breakpoints.only("xs")]: {
            //     top: "unset",
            //     bottom: 0,
            //     left: 0,
            //     right: 0,
            //     borderRadius: 0,
            //     transform: "none",
            //     maxWidth: "unset",
            //   },
            // })}
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                position: "absolute",
                overflowY: "auto",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                maxWidth: "100%",
                width: "100%", 
                maxHeight: "100%", 
              },
            })}
          >
            <h2 class="text-md text-[#0F3D79] font-semibold py-2">
              {" "}
              Add Award 1
            </h2>

            <Textarea
              placeholder="Type here…"
              value={awards.award1}
              onChange={handelAwardsChange}
              minRows={2}
              maxRows={4}
              endDecorator={
                <Box
                  sx={{
                    display: "flex",
                    gap: "var(--Textarea-paddingBlock)",
                    pt: "var(--Textarea-paddingBlock)",
                    borderTop: "1px solid",
                    borderColor: "divider",
                    flex: "auto",
                  }}
                >
                  <Typography
                    level="body-xs"
                    sx={{
                      ml: "auto",
                      color: awards.award1.length < 200 ? "black" : "red",
                    }}
                  >
                    {awards.award1.length < 200
                      ? `${awards.award1.length} character(s)`
                      : characterLimit}
                  </Typography>
                </Box>
              }
              sx={{
                minWidth: 500,
              }}
            />

            {/* <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Award name</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Award name"
                  name="award1" // Make sure to set the name attribute
                  value={awards.award1}
                  onChange={handelAwardsChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div> */}

            <div style={{ marginTop: "20px" }}>
              <FormControl variant="outlined">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="awarded_year1"
                    slotProps={{ textField: { size: "small", error: error } }}
                    label="Awarded year"
                    views={["year"]}
                    value={dayjs(awarded_year1)}
                    onChange={handelAwaYear1}
                    shouldDisableYear={isInCurrentYear}
                  />
                  {error && (
                    <FormHelperText error={error}>Invalid year</FormHelperText>
                  )}
                </LocalizationProvider>
              </FormControl>
            </div>

            {/* <h2 class="text-md font-semibold text-[#0F3D79] py-4">Awards 2</h2>

            <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth size='small'>
                <InputLabel>Award name</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Award name"
                  name="award2" // Make sure to set the name attribute
                  value={awards.award2}
                  onChange={handelAwardsChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth size='small'>
                      <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                        >
                          <DatePicker
                           name="awarded_year2"
                           slotProps={{ textField: { size: 'small',  error: false, } }}
                            label="Awarded year"
                            views={["year"]}
                            value={dayjs(awarded_year2)}
                            onChange={(e)=>{ e? setAwarded_year2(e): setAwarded_year2(null)}}
                            shouldDisableYear={isInCurrentYear}
                          />
                        </LocalizationProvider>
                      </FormControl>
            </div> */}

            <h2 class="text-md text-[#0F3D79] font-semibold py-2">
              {" "}
              Add Award 2
            </h2>

            <Textarea
              placeholder="Type here…"
              value={awardsec.award2}
              onChange={handelAwardsChangeSec}
              minRows={2}
              maxRows={4}
              endDecorator={
                <Box
                  sx={{
                    display: "flex",
                    gap: "var(--Textarea-paddingBlock)",
                    pt: "var(--Textarea-paddingBlock)",
                    borderTop: "1px solid",
                    borderColor: "divider",
                    flex: "auto",
                  }}
                >
                  <Typography
                    level="body-xs"
                    sx={{
                      ml: "auto",
                      color: awardsec.award2.length < 200 ? "black" : "red",
                    }}
                  >
                    {awardsec.award2.length < 200
                      ? `${awardsec.award2.length} character(s)`
                      : characterLimit}
                  </Typography>
                </Box>
              }
              sx={{
                minWidth: 500,
              }}
            />

            {/* <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Award name</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Award name"
                  name="award1" // Make sure to set the name attribute
                  value={awards.award1}
                  onChange={handelAwardsChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div> */}

            <div style={{ marginTop: "20px" }}>
              <FormControl variant="outlined">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="awarded_year2"
                    slotProps={{ textField: { size: "small", error: error } }}
                    label="Awarded year"
                    views={["year"]}
                    value={dayjs(awarded_year2)}
                    onChange={handelAwaYear2}
                    shouldDisableYear={isInCurrentYear}
                  />
                  {error && (
                    <FormHelperText error={error}>Invalid year</FormHelperText>
                  )}
                </LocalizationProvider>
              </FormControl>
            </div>

            <div className="flex justify-end mt-4">
              <Button variant="outlined" color="neutral" onClick={handelClose4}>
                Cancel
              </Button>
              <Button
                variant="solid"
                color="contained"
                style={{
                  backgroundColor: "#0066A2",
                  color: "white",
                  marginLeft: "10px",
                }}
                onClick={awardSumbit}
              >
                Submit{" "}
              </Button>
            </div>
          </ModalDialog>
        </Modal>
      </>
    </div>
  );
}

export default Awards;
