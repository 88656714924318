import React from 'react'
import JobDisplays from './JobDisplays'
import JobDetails from './JobDetails'
import AppliableJobs from './AppliableJobs'

function index() {
  return (
    <div>
        <JobDisplays/>
    </div>
  )
}

export default index;