import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { individualUserSelector, removeOneNotifi } from "../../api/individualUser";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
    customTableRow: {
      '& > .MuiTableCell-root': {
        padding: '0px', // Adjust the padding value as needed
      },
    },
  }));

function Accepts({accepts}) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const nav = useNavigate()
    const {individualtoken, user} = useSelector(individualUserSelector)

    const viewNotification = (item) => {
        dispatch(removeOneNotifi(item?._id,{userId:user?._id},individualtoken))
        nav(`/profile/${item?.user?._id}`)
    }
    return (
      <div>
          <TableContainer  component={Paper}>
          <TableBody >
            {accepts?.map((item, i) => {
              return (
                <TableRow key={i} className={classes.customTableRow} sx={{cursor:'pointer'}} onClick={()=>viewNotification(item)}>
                  <TableCell className="w-[20%]"><img className="relative w-12 h-12 m-2 mx-auto rounded-full object-cover object-right" src={item?.user?.profile_completion?.profile_pic ? item?.user?.profile_completion?.profile_pic : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"} alt="img" /></TableCell>
                  <TableCell className="w-[65%]">{`${item?.from} ${item?.msg}`}</TableCell>
                  <TableCell>{moment(item?.date).fromNow()}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TableContainer>
      </div>
    )
}

export default Accepts