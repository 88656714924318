import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@mui/material/Modal";
import { ModalDialog } from "@mui/joy";
import { useDispatch } from "react-redux";
import { updateIndividual } from "../../api/individualUser";
import Button from "@mui/joy/Button";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
} from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { FormHelperText } from "@mui/material";
import { toast } from "react-toastify";

function WorkExperiance({ user, individualtoken }) {
  const dispatch = useDispatch();
  const [open6, setOpen6] = useState(false);
  const [experiance, setExperiance] = useState({
    company_name1: "",
    designation1: "",
    company_location1: "",
    company_name2: "",
    designation2: "",
    company_location2: "",
  });
  const [expStart_year1, setExpStart_year1] = useState(null);
  const [expEnd_year1, setExpEnd_year1] = useState(null);
  const [expStart_year2, setExpStart_year2] = useState(null);
  const [expEnd_year2, setExpEnd_year2] = useState(null);

  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);

  useEffect(() => {
    preFill();
  }, [user]);

  const preFill = () => {
    setExperiance({
      company_name1: user?.profile_completion?.experience_details_1
        ?.company_name1
        ? user?.profile_completion?.experience_details_1?.company_name1
        : "",
      designation1: user?.profile_completion?.experience_details_1?.designation1
        ? user?.profile_completion?.experience_details_1?.designation1
        : "",
      company_location1: user?.profile_completion?.experience_details_1
        ?.company_location1
        ? user?.profile_completion?.experience_details_1?.company_location1
        : "",
      company_name2: user?.profile_completion?.experience_details_2
        ?.company_name2
        ? user?.profile_completion?.experience_details_2?.company_name2
        : "",
      designation2: user?.profile_completion?.experience_details_2?.designation2
        ? user?.profile_completion?.experience_details_2?.designation2
        : "",
      company_location2: user?.profile_completion?.experience_details_2
        ?.company_location2
        ? user?.profile_completion?.experience_details_2?.company_location2
        : "",
    });
    setExpStart_year1(
      user?.profile_completion?.experience_details_1?.expStart_year1
        ? user?.profile_completion?.experience_details_1?.expStart_year1
        : null
    );
    setExpEnd_year1(
      user?.profile_completion?.experience_details_1?.expEnd_year1
        ? user?.profile_completion?.experience_details_1?.expEnd_year1
        : ""
    );
    setExpStart_year2(
      user?.profile_completion?.experience_details_2?.expStart_year2
        ? user?.profile_completion?.experience_details_2?.expStart_year2
        : null
    );
    setExpEnd_year2(
      user?.profile_completion?.experience_details_2?.expEnd_year2
        ? user?.profile_completion?.experience_details_2?.expEnd_year2
        : null
    );
  };

  const isInCurrentYear = (date) =>
    date.get("year", "mm") > dayjs().get("year", "mm");

  const handelClose6 = () => {
    preFill();
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setOpen6(false);
  };

  //experiance change handel function

  const handelExpChange = (event) => {
    const { name, value } = event.target;

    setExperiance((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // experiance submit function

  const expSubmit = (e) => {
    e.preventDefault();
    if (error1 || error2 || error3 || error4) {
      toast.warning("Please fill valid year", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const {
        company_name1,
        designation1,
        company_location1,
        company_name2,
        designation2,
        company_location2,
      } = experiance;
      const exp_details = {
        experience_details_1: {
          company_name1,
          designation1,
          company_location1,
          expStart_year1,
          expEnd_year1,
        },
        experience_details_2: {
          company_name2,
          designation2,
          company_location2,
          expStart_year2,
          expEnd_year2,
        },
      };
      dispatch(updateIndividual(user?._id, { exp_details }, individualtoken));
      handelClose6();
    }
  };

  //date picker functions
  const handelExpYear1 = (event) => {
    setError1(false);
    if (event == null || event == undefined) {
      setExpStart_year1(0);
    } else {
      setExpStart_year1(event);
      setError1(false);
    }
    if (event?.get("year", "mm") > dayjs().get("year", "mm")) {
      setError1(true);
    }
  };

  const handelExpYear2 = (event) => {
    setError2(false);
    if (event == null || event == undefined) {
      setExpEnd_year1(0);
    } else {
      setExpEnd_year1(event);
      setError2(false);
    }
    if (event?.get("year", "mm") > dayjs().get("year", "mm")) {
      setError2(true);
    }
  };

  const handelExpYear3 = (event) => {
    setError3(false);
    if (event == null || event == undefined) {
      setExpStart_year2(0);
    } else {
      setExpStart_year2(event);
      setError3(false);
    }
    if (event?.get("year", "mm") > dayjs().get("year", "mm")) {
      setError3(true);
    }
  };

  const handelExpYear4 = (event) => {
    setError4(false);
    if (event == null || event == undefined) {
      setExpEnd_year2(0);
    } else {
      setExpEnd_year2(event);
      setError4(false);
    }
    if (event?.get("year", "mm") > dayjs().get("year", "mm")) {
      setError4(true);
    }
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div>
        <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
          <div class="w-auto">
            <h3
              class="text-lg font-semibold text-[#0F3D79] mb-3"
              style={{ marginLeft: "10px" }}
            >
              Career Details
            </h3>
          </div>
          {user?.profile_completion?.experience_details_1?.company_name1 ==
            "" ||
          user?.profile_completion?.experience_details_2?.company_name2 ==
            "" ? (
            <button class="w-auto px-2 mb-3 text-gray-500">
              <AddIcon onClick={() => setOpen6(true)} />
            </button>
          ) : (
            <button class="w-auto px-2 mb-3 text-gray-300 hover:text-gray-500">
              <EditIcon onClick={() => setOpen6(true)} />
            </button>
          )}
          {/* <button class="w-auto px-2 mb-10 text-gray-500">
                            <AddIcon />
                          </button>
                          <button class="w-auto px-2 mb-10 text-gray-300 hover:text-gray-500">
                            <EditIcon />
                          </button> */}
        </div>
        <div className="mt-5 text-md font-medium text-gray-500">
          <p class="font-semibold" style={{ color: "#000" }}>
            {user?.profile_completion?.experience_details_1?.company_name1}
          </p>

          <p>{user?.profile_completion?.experience_details_1?.designation1}</p>
          <p>
            {user?.profile_completion?.experience_details_1?.company_location1}
          </p>

          <p>
            {
              user?.profile_completion?.experience_details_1?.expStart_year1 &&
              user?.profile_completion?.experience_details_1?.expEnd_year1
                ? `${dayjs(
                    user.profile_completion.experience_details_1.expStart_year1
                  ).format("YYYY", "MM")}-${dayjs(
                    user.profile_completion.experience_details_1.expEnd_year1
                  ).format("YYYY", "MM")}`
                : "" //display nothing
            }
          </p>
        </div>
        <div className="mt-5 text-md font-medium text-gray-500">
          <p class="font-semibold" style={{ color: "#000" }}>
            {user?.profile_completion?.experience_details_2?.company_name2}
          </p>

          <p>{user?.profile_completion?.experience_details_2?.designation2}</p>
          <p>
            {user?.profile_completion?.experience_details_2?.company_location2}
          </p>

          <p>
            {
              user?.profile_completion?.experience_details_2?.expStart_year2 &&
              user?.profile_completion?.experience_details_2?.expEnd_year2
                ? `${dayjs(
                    user.profile_completion.experience_details_2.expStart_year2
                  ).format("YYYY", "MM")}-${dayjs(
                    user.profile_completion.experience_details_2.expEnd_year2
                  ).format("YYYY", "MM")}`
                : "" //display nothing
            }
          </p>
        </div>
      </div>
      {/* ------------------------Work Experiance-------------------------------------------- */}
      <>
        <Modal open={open6}>
          <ModalDialog
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                top: "unset",
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
                transform: "none",
                maxWidth: "unset",
              },
            })}
          >
            <h2 class="text-md font-semibold text-[#0F3D79]">
              Latest Experience
            </h2>

            <div style={{ marginTop: "5px" }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Company name</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Company name"
                  name="company_name1" // Make sure to set the name attribute
                  value={experiance.company_name1}
                  onChange={handelExpChange}
                  format="MM/YYYY"
                  inputFormat="MM/YYYY"
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Designation</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Designation"
                  name="designation1" // Make sure to set the name attribute
                  value={experiance.designation1}
                  onChange={handelExpChange}
                  format="MM/YYYY"
                  inputFormat="MM/YYYY"
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Location </InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Location "
                  name="company_location1" // Make sure to set the name attribute
                  value={experiance.company_location1}
                  onChange={handelExpChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div className="mt-3">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="expStart_year1"
                        slotProps={{
                          textField: { size: "small", error: error1 },
                        }}
                        label="Start year"
                        views={["year", "month"]}
                        value={dayjs(expStart_year1)}
                        onChange={handelExpYear1}
                        shouldDisableYear={isInCurrentYear}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                      />
                      {error1 && (
                        <FormHelperText error={error1}>
                          Invalid year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="expEnd_year1"
                        slotProps={{
                          textField: { size: "small", error: error2 },
                        }}
                        label="End year"
                        views={["year", "month"]}
                        value={dayjs(expEnd_year1)}
                        onChange={handelExpYear2}
                        shouldDisableYear={isInCurrentYear}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                      />
                      {error2 && (
                        <FormHelperText error={error2}>
                          Invalid year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <h2 class="text-md font-semibold text-[#0F3D79] py-4">
              Previous Experience
            </h2>

            <div>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Company name</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Company name"
                  name="company_name2" // Make sure to set the name attribute
                  value={experiance.company_name2}
                  onChange={handelExpChange}
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Designation</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Designation"
                  name="designation2" // Make sure to set the name attribute
                  value={experiance.designation2}
                  onChange={handelExpChange}
                  format="MM/YYYY"
                  inputFormat="MM/YYYY"
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Location </InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Location "
                  name="company_location2" // Make sure to set the name attribute
                  value={experiance.company_location2}
                  onChange={handelExpChange}
                  format="MM/YYYY"
                  inputFormat="MM/YYYY"
                  // You can add more attributes or props as needed
                />
              </FormControl>
            </div>

            <div className="mt-3">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="expStart_year2"
                        slotProps={{
                          textField: { size: "small", error: error3 },
                        }}
                        label="Start year"
                        views={["year", "month"]}
                        value={dayjs(expStart_year2)}
                        onChange={handelExpYear3}
                        shouldDisableYear={isInCurrentYear}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                      />
                      {error3 && (
                        <FormHelperText error={error3}>
                          Invalid year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="expStart_year2"
                        slotProps={{
                          textField: { size: "small", error: error4 },
                        }}
                        label="End year"
                        views={["year", "month"]}
                        value={dayjs(expEnd_year2)}
                        onChange={handelExpYear4}
                        shouldDisableYear={isInCurrentYear}
                        format="MM/YYYY"
                        inputFormat="MM/YYYY"
                      />
                      {error4 && (
                        <FormHelperText error={error4}>
                          Invalid year
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className="flex justify-end  mt-4">
              <Button variant="outlined" color="neutral" onClick={handelClose6}>
                Cancel
              </Button>
              <Button
                variant="solid"
                color="contained"
                style={{
                  backgroundColor: "#0066A2",
                  color: "white",

                  marginLeft: "10px",
                }}
                onClick={expSubmit}
              >
                Submit{" "}
              </Button>
            </div>
          </ModalDialog>
        </Modal>
      </>
    </div>
  );
}

export default WorkExperiance;
