import React from 'react'
import Logo from '../images/logo.png'

export default function Privacypolicy() {
  return (
    <div>
         <section
        class=" max-w-screen-xl min-h-[78vh] px-4 py-6 mx-auto text-justify mb-14"
        style={{ fontFamily: "Lato" }}
      >
           <img
                    class="block"
                    src={Logo}
                    alt=""
                  />
                
              
        <ul className="list-disc">
          <p class="mb-2 md:mb-5 md:mt-5  text-[20px] md:text-[25px] text-center text-[#0F3D79] font-bold font-heading">
            Privacy Policy
          </p>

          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
            Our Privacy Policy BHARC INDIA PRIVATE LIMITED, as it may change
            from time to time, is a part of this Agreement.
          </p>

          <p className="md:text-[16px] text-[14px] text-gray-600  mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Securities Laws : </span>   This App may include statements concerning our
            operations, prospects, strategies, financial condition, future
            economic performance and demand for our products or services, as
            well as our intentions, plans and objectives, that are
            forward-looking statements. These statements are based upon a number
            of assumptions and estimates which are subject to significant
            uncertainties, many of which are beyond our control. When used on
            our App, words like “anticipates”, “expects”, “believes”,
            “estimates”, “seeks”, “plans”, “intends” and similar expressions are
            intended to identify forward-looking statements designed to fall
            within securities law safe harbours for forward-looking statements.
            The App and the information contained herein does not constitute an
            offer or a solicitation of an offer for sale of any securities. None
            of the information contained herein is intended to be, and shall not
            be deemed to be, incorporated into any of our securities-related
            filings or documents.{" "}
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Links to Other WebApps : </span>  The App contains links to other Web Apps. We
            are not responsible for the content, accuracy or opinions expressed
            in such WebApps, and such WebApps are not investigated, monitored or
            checked for accuracy or completeness by us. Inclusion of any linked
            Web App on our App does not imply approval or endorsement of the
            linked Web App by us. If you decide to leave our App and access
            these third-party Apps, you do so at your own risk.
          </p>

          <p className="md:text-[16px] text-[14px] text-gray-600  mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Submissions : </span> All suggestions, ideas, notes, concepts and other
            information you may from time to time send to us (collectively,
            “Submissions”) shall be deemed and shall remain our sole property
            and shall not be subject to any obligation of confidence on our
            part. Without limiting the foregoing, we shall be deemed to own all
            known and hereafter existing rights of every kind and nature
            regarding the Submissions and shall be entitled to unrestricted use
            of the Submissions for any purpose, without compensation to the
            provider of the Submissions.
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Venue; Applicable Law : </span> You agree that all actions or proceedings
            arising directly or indirectly out of this agreement, or your use of
            the app or any samples or products obtained by you through such use,
            shall be litigated in the Court of Bengaluru, Karnataka, India. You
            are expressly submitting and consenting in advance to such
            jurisdiction in any action or proceeding in any of such courts, and
            are waiving any claim that Perth, Bengaluru, Karnataka, India is an
            inconvenient forum or an improper forum based on lack of venue. This
            app is created and controlled by BHARC INDIA PRIVATE LIMITED, in the
            State of Karnataka, India. . This App is created and controlled by
            BHARC INDIA PRIVATE LIMITED , in the State of Karnataka, India. As
            such, the laws of INDIA will govern the terms and conditions
            contained in this Agreement and elsewhere throughout the App,
            without giving effect to any principles of conflicts of laws.
          </p>

          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Lapsed Accounts : </span>Lapsed Accounts: In order to keep BHARC INDIA PRIVATE LIMITED
            membership roster current, if a Member does not access his or her
            account for a period of 180 DAYS days or more, BHARC INDIA PRIVATE
            LIMITED may, in its sole discretion, terminate such Member’s
            account. BHARC INDIA PRIVATE LIMITED will endeavour to notify a
            Member of BHARC INDIA PRIVATE LIMITED intent to terminate such
            Member’s account by notice to such Member’s provided email address
            at least 14 DAYS days prior to deactivation. If the Member fails to
            respond to such email notice within 14 DAYS after the day it is sent
            by BHARC INDIA PVT LTD, such Member’s account will be terminated as
            noted above. Therefore, BHARC INDIA PRIVATE LIMITED strongly
            recommends that all Members keep their accounts and contact data
            current and in use. While BHARC INDIA PRIVATE LIMITED desires to
            prevent active accounts from being terminated prematurely, BHARC
            INDIA PRIVATE LIMITED has no obligation to maintain accounts that
            appear to BHARC INDIA PRIVATE LIMITED to have been abandoned. Each
            Member agrees that failure to access his or her account for BHARC
            INDIA PRIVATE LIMITED days or more conclusively indicates that such
            Member’s account has been abandoned and that the account may
            therefore be terminated.
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Verify Members’ Address : </span> BHARC INDIA PRIVATE LIMITED reserves the
            right to contact a Member via email to verify the accuracy of
            account information (including the Member’s correct name and
            address) that is needed to provide the Member with the information
            he or she requested from BHARC INDIA PRIVATE LIMITED
          </p>
          <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            This following document sets forth the Privacy Policy for the BHARC.
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600">
            BHARC INDIA PRIVATE LIMITED is committed to providing you with the
            best possible customer service experience. BHARC INDIA PRIVATE
            LIMITED is bound by the Privacy Act 1988 (Crh), which sets out a
            number of principles concerning the privacy of individuals.
          </p>

          <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Collection of your personal information
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600">
            There are many aspects of the App which can be viewed without
            providing personal information, however, for access to future BHARC
            INDIA PRIVATE LIMITED customer support features you are required to
            submit personally identifiable information. This may include but not
            limited to a unique username and password, or provide sensitive
            information in the recovery of your lost password. Profile details,
            postings and uploads, contents, location, messages, information
            provided by research organisations, universities, companies., are
            collected.
          </p>

          <p class="mb-2 md:mb-2 md:mt-10 text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Sharing of your personal information
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600">
            We may occasionally hire other companies to provide services on our
            behalf, including but not limited to handling customer support
            enquiries, processing transactions . Those companies will be
            permitted to obtain only the personal information they need to
            deliver the service. BHARC INDIA PRIVATE LIMITED takes reasonable
            steps to ensure that these organisations are bound by
            confidentiality and privacy obligations in relation to the
            protection of your personal information. But we don’t share with any
            third parties.
          </p>
          <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Use of your personal information
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
            For each visitor to reach the App, we expressively collect the
            following non-personally identifiable information, including but not
            limited to browser type, version and language, operating system,
            pages viewed while browsing the App, page access times and referring
            App address. This collected information is used solely internally
            for the purpose of gauging visitor traffic, trends and delivering
            personalised content to you while you are at this App.
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 ">
            From time to time, we may use customer information for new,
            unanticipated uses not previously disclosed in our privacy notice.
            If our information practices change at some time in the future we
            will use for these new purposes only, data collected from the time
            of the policy change forward will adhere to our updated practices
          </p>
          <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Changes to this privacy policy
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600">
            BHARC INDIA PRIVATE LIMITED reserves the right to make amendments to
            this Privacy Policy at any time. If you have objections to the
            Privacy Policy, you should not access or use the App.
          </p>

          <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Accessing your personal information
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600">
            You have a right to access your personal information, subject to
            exceptions allowed by law. If you would like to do so, please let us
            know. You may be required to put your request in writing for
            security reasons. BHARC INDIA PRIVATE LIMITED reserves the right to
            charge a fee for searching for, and providing access to, your
            information on a per request basis.
          </p>
          <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Contacting US
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600">
            BHARC INDIA PRIVATE LIMITED welcomes your comments regarding this
            Privacy Policy. If you have any questions about this Privacy Policy
            and would like further information, please contact us by any of the
            following means during business hours Monday to Friday.
          </p>
        </ul>
      </section>
    </div>
  )
}
