import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./Explore.css";
import WorkIcon from "@mui/icons-material/Work";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Modal from "@mui/material/Modal";

import AddIcon from "@material-ui/icons/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/joy";
import { TextField, IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../images/comingsoon.png";

import FilterIcon from "@mui/icons-material/FilterList";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import image2 from "../../images/logo.png";
import image3 from "../../images/bharcimg.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import ShareIcon from "@mui/icons-material/Share";
import SaveIcon from "@mui/icons-material/Save";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ExploreIcon from "@mui/icons-material/Explore";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import { individualUserSelector } from "../../api/individualUser";
import { saveExplore, unSaveExplore } from "../../api/explore";
import { RWebShare } from "react-web-share";

function SavedEvents() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );

  const [savedIoiDocuments, setSavedIoiDocuments] = useState([]);

  useEffect(() => {
    const ioiDocuments = user?.explore_saved?.filter(
      (doc) => doc.explore_posted_by_ioi
    );

    setSavedIoiDocuments(ioiDocuments || []);
  }, [user]);

  const handleSaveTopic = (exploreId) => {
    // alert(exploreId, "exploreid");
    if (user?.explore_saved?.some((saved) => saved._id === exploreId)) {
     
      dispatch(unSaveExplore(user?._id, exploreId, individualtoken));
    } else {
    
      dispatch(saveExplore(user?._id, exploreId, individualtoken));
    }
  };


  const nav = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deletionId, setDeletionId] = useState("");

  const [isSaved, setIsSaved] = useState(false);

  const handleClick = () => {
    setIsSaved((prevState) => !prevState); // Toggle the state between true and false
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              {/* <div class="w-full mb-6">
                <div class="relative p-6 text-center bg-gray-100 rounded-xl">
                  <img
                    class="block mx-auto mb-5 w-28 h-28 rounded-full"
                    src={
                      "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                    }
                    alt=""
                  />
                  <h4 class="text-lg text-[#0F3D79] font-bold mb-3">
        
                  </h4>
                  <div class="flex items-center justify-center mb-6">
                    <p class="text-md font-semibold text-gray-500">
                 
                    </p>
                  </div>
                </div>
              </div> */}
              <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/explore">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/OtherEvents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        OtherEvents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/add">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/SavedEvents">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("SavedEvents")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            {/* <div class="w-full lg:w-[78%] px-3">
              <div class="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl flex items-center justify-center">
                <img class="w-100 h-100 object-contain" src={Image1} />
              </div>
            </div> */}
            <div className="w-full lg:w-[78%] px-1">
              <div className="h-full py-1 px-1 sm:px-1 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center lg:items-end">
                    <div className="flex-grow flex items-center -mt-1">
                      {/* Heading */}
                      <h2 className="text-xl font-semibold text-[#0F3D79] lg:mr-6 mb-4 lg:mb-3">
                        Saved Events <span className="text-sm"> </span>
                      </h2>
                    </div>

                    {/* Search, Filter, and Date Buttons */}
                    <div className="flex flex-col lg:flex-row justify-end items-center w-full lg:w-auto">
                      {/* Search Bar */}
                      <div className="relative w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          value={""}
                          onChange={""}
                          type="text"
                          placeholder="Search company name"
                          className="py-2 pl-10 pr-4 w-full lg:w-[300px] rounded-xl border border-gray-300"
                        />
                      </div>

                      {/* Filter Button */}
                      <div className="w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <button
                          className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
                          type="button"
                        >
                          <FilterIcon /> Filter
                        </button>
                      </div>

                      {/* Date Button */}
                      <div className="w-full lg:w-auto">
                        <button
                          className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
                          type="button"
                        >
                          <CalendarTodayIcon /> Date
                        </button>
                      </div>
                    </div>
                  </div>

                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3">
                    Conferences
                  </h2>

                  {/* Topic Cards */}
                  <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {savedIoiDocuments
                      ?.filter(
                        (item) =>
                          item?.event_type === "Conferences" &&
                          item?.explore_posted_by_ioi
                      ) // Filter by event_type and non-null explore_posted_by_ioi
                      .map((item, index) => (
                        <div
                          key={index}
                          className="bg-white p-6 rounded-xl shadow-md"
                        >
                          <NavLink to="/ViewAds">
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  item?.explore_posted_by_ioi
                                    ?.profile_completion?.logo || image2
                                } // Use profile logo or fallback image
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {item?.explore_posted_by_ioi?.name ||
                                    "Organization Name"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {item?.explore_posted_by_ioi?.sub_category ||
                                    "National Level Organization"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {item?.event_name || "Ad title goes here"}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {item.description ||
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sollicitudin at enim non ultrices. Etiam fringilla pellentesque dictum. Morbi sollicitudin placerat vulputate."}{" "}
                              <a href="#" className="text-blue-600">
                                Read more
                              </a>
                            </p>
                            <div className="flex justify-center items-center h-40 bg-gray-100 rounded-lg overflow-hidden mb-4">
                              <img
                                src={item?.document || image3} // Use event document or fallback image
                                alt="Description of the image"
                                className="w-full h-40 object-cover"
                              />
                            </div>
                          </NavLink>
                          <div className="flex items-center mt-4 gap-x-4 md:gap-x-[10px]">
                            <RWebShare
                              data={{
                                text: "Check out this event",
                                url: `https://ioi.bharc.in/Publish_view/${item?._id}`,
                                title: "Event Share",
                              }}
                              onClick={() =>
                                console.log("Shared successfully!")
                              }
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>

                            {user?.explore_saved?.some(
                              (saved) => saved?._id === item?._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSaveTopic(item?._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{
                                    fill: "white",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSaveTopic(item?._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{
                                    fill: "#346BB7",
                                  }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>

                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3 mt-5">
                    Competitions
                  </h2>
                  <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {savedIoiDocuments
                      ?.filter(
                        (item) =>
                          item?.event_type === "Competitions" &&
                          item?.explore_posted_by_ioi
                      ) // Filter by event_type and non-null explore_posted_by_ioi
                      .map((item, index) => (
                        <div
                          key={index}
                          className="bg-white p-6 rounded-xl shadow-md"
                        >
                          <NavLink to="/ViewAds">
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  item?.explore_posted_by_ioi
                                    ?.profile_completion?.logo || image2
                                } // Use profile logo or fallback image
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {item?.explore_posted_by_ioi?.name ||
                                    "Organization Name"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {item?.explore_posted_by_ioi?.sub_category ||
                                    "National Level Organization"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {item?.event_name || "Ad title goes here"}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {item.description ||
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sollicitudin at enim non ultrices. Etiam fringilla pellentesque dictum. Morbi sollicitudin placerat vulputate."}{" "}
                              <a href="#" className="text-blue-600">
                                Read more
                              </a>
                            </p>
                            <div className="flex justify-center items-center h-40 bg-gray-100 rounded-lg overflow-hidden mb-4">
                              <img
                                src={item?.document || image3} // Use event document or fallback image
                                alt="Description of the image"
                                className="w-full h-40 object-cover"
                              />
                            </div>
                          </NavLink>
                          <div className="flex items-center mt-4 gap-x-4 md:gap-x-[10px]">
                            <RWebShare
                              data={{
                                text: "Check out this event",
                                url: `https://ioi.bharc.in/Publish_view/${item?._id}`,
                                title: "Event Share",
                              }}
                              onClick={() =>
                                console.log("Shared successfully!")
                              }
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>

                            {user?.explore_saved?.some(
                              (saved) => saved?._id === item?._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSaveTopic(item?._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{
                                    fill: "white",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSaveTopic(item?._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{
                                    fill: "#346BB7",
                                  }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>

                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3 mt-5">
                    Other Events
                  </h2>

                  <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {savedIoiDocuments
                      ?.filter(
                        (item) =>
                          item?.event_type === "Other events" &&
                          item?.explore_posted_by_ioi
                      ) // Filter by event_type and non-null explore_posted_by_ioi
                      .map((item, index) => (
                        <div
                          key={index}
                          className="bg-white p-6 rounded-xl shadow-md"
                        >
                          <NavLink to="/ViewAds">
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  item?.explore_posted_by_ioi
                                    ?.profile_completion?.logo || image2
                                } // Use profile logo or fallback image
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {item?.explore_posted_by_ioi?.name ||
                                    "Organization Name"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {item?.explore_posted_by_ioi?.sub_category ||
                                    "National Level Organization"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {item?.event_name || "Ad title goes here"}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {item.description ||
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sollicitudin at enim non ultrices. Etiam fringilla pellentesque dictum. Morbi sollicitudin placerat vulputate."}{" "}
                              <a href="#" className="text-blue-600">
                                Read more
                              </a>
                            </p>
                            <div className="flex justify-center items-center h-40 bg-gray-100 rounded-lg overflow-hidden mb-4">
                              <img
                                src={item?.document || image3} // Use event document or fallback image
                                alt="Description of the image"
                                className="w-full h-40 object-cover"
                              />
                            </div>
                          </NavLink>
                          <div className="flex items-center mt-4 gap-x-4 md:gap-x-[10px]">
                            <RWebShare
                              data={{
                                text: "Check out this event",
                                url: `https://ioi.bharc.in/Publish_view/${item?._id}`,
                                title: "Event Share",
                              }}
                              onClick={() =>
                                console.log("Shared successfully!")
                              }
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>

                            {user?.explore_saved?.some(
                              (saved) => saved?._id === item?._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSaveTopic(item?._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{
                                    fill: "white",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSaveTopic(item?._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{
                                    fill: "#346BB7",
                                  }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SavedEvents;
