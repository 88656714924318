import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@mui/material/Modal";
import { ModalDialog, Textarea } from "@mui/joy";
import { useDispatch } from "react-redux";
import { updateIndividual } from "../../api/individualUser";
import Button from "@mui/joy/Button";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Box, FormHelperText, Typography } from "@mui/material";
import { toast } from "react-toastify";

function Achivements({ user, individualtoken }) {
  const dispatch = useDispatch();
  const [open5, setOpen5] = React.useState(false);
  const [achived_year1, setAchived_year1] = useState(null);
  const [achived_year2, setAchived_year2] = useState(null);

  const [achivemenst, setAchivements] = useState({
    achivement1: "",
  });



  const [achivementState, setAchivementState] = useState({
    achivement2: "",
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    preFill();
  }, [user]);

  const preFill = () => {
    setAchivements({
      achivement1: user?.profile_completion?.achivement_details_1?.achivement1
        ? user?.profile_completion?.achivement_details_1.achivement1
        : "",
    });

    setAchivementState({
      achivement2: user?.profile_completion?.achivement_details_2?.achivement2
        ? user?.profile_completion?.achivement_details_2.achivement2
        : "",
    });

    setAchived_year1(
      user?.profile_completion?.achivement_details_1?.achived_year1
        ? user?.profile_completion?.achivement_details_1.achived_year1
        : null
    );
    setAchived_year2(
      user?.profile_completion?.achivement_details_2?.achived_year2
        ? user?.profile_completion?.achivement_details_2.achived_year2
        : null
    );
  };

  const isInCurrentYear = (date) => date.get("year") > dayjs().get("year");

  const handelClose5 = () => {
    preFill();
    setError(false);
    setOpen5(false);
  };

  //achivements change handel function

  const handleAchiveChange = (event) => {
    const { name, value } = event.target;
    if (value.length <= 380) {
      setAchivements({ achivement1: value });
    }
  };

  const handleAchiveChange1 = (event) => {
    const { name, value } = event.target;
    if (value.length <= 380) {
      setAchivementState({ achivement2: value });
    }
  };

  const [characterLimit, setCharacterLimit] = useState(
    "Your description cannot exceed 380 characters and above."
  );

  //achivements submit handel function

  const achivemehntSumbit = (e) => {
    e.preventDefault();
    const { achivement1 } = achivemenst;
    const { achivement2 } = achivementState;

    if (!achivement1 && !achivement2) {
      toast.warning("Please provide at least one achivement detail", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (error) {
      toast.warning("Please fill valid year", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const achivemnent_details = {
        achivement_details_1: {
          achivement1,
          achived_year1,
        },
        achivement_details_2: {
          achivement2,
          achived_year2,
        },
      };
      dispatch(
        updateIndividual(user?._id, { achivemnent_details }, individualtoken)
      );
      handelClose5();
    }
  };

  //date picker functions
  const handelAchYear1 = (event) => {
    setError(false);
    if (event == null || event == undefined) {
      setAchived_year1(0);
    } else {
      setAchived_year1(event);
      setError(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError(true);
    }
  };
  const handelAchYear2 = (event) => {
    setError(false);
    if (event == null || event == undefined) {
      setAchived_year2(0);
    } else {
      setAchived_year2(event);
      setError(false);
    }
    if (event?.get("year") > dayjs().get("year")) {
      setError(true);
    }
  };
  return (
    <div style={{ fontFamily: "Lato" }}>
      <div class=" p-4 bg-gray-100 rounded-xl  h-[343px]">
        <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
          <div class="w-auto px-4">
            <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
              Achievements and Awards
            </h3>
          </div>
          {user?.profile_completion?.achivement_details_1?.achivement1 == "" ? (
            <button class="w-auto px-2 mb-4 text-gray-500">
              <AddIcon onClick={() => setOpen5(true)} />
            </button>
          ) : (
            <button class="w-auto px-2 mb-4 text-gray-300 hover:text-gray-500">
              <EditIcon onClick={() => setOpen5(true)} />
            </button>
          )}
        </div>

        <div className="mt-2">
          {/* <h4 class="text-lg text-gray-500 font-medium">Achievement Name :</h4> */}
          <p class="py-1 text-md font-semibold text-gray-800">
            {user?.profile_completion?.achivement_details_1?.achivement1}
          </p>
        </div>
        <div className=" flex mt-1">
          {user?.profile_completion?.achivement_details_1?.achived_year1 ? (
            <h4 class="text-md font-semibold text-[#0F3D79]"> Year :</h4>
          ) : null}
          <p class="ml-2 text-md font-semibold text-gray-800">
            {user?.profile_completion?.achivement_details_1?.achived_year1
              ? dayjs(
                  user?.profile_completion?.achivement_details_1?.achived_year1
                ).format("YYYY")
              : ""}
          </p>
        </div>

        <div className="mt-2">
          {/* <h4 class="text-lg text-gray-500 font-medium">Achievement Name :</h4> */}
          <p class="py-1 text-md font-semibold text-gray-800">
            {user?.profile_completion?.achivement_details_2?.achivement2}
          </p>
        </div>
        <div className=" flex mt-1">
          {user?.profile_completion?.achivement_details_2?.achived_year2 ? (
            <h4 class="text-md font-semibold text-[#0F3D79]"> Year :</h4>
          ) : null}
          <p class="ml-2 text-md font-semibold text-gray-800">
            {user?.profile_completion?.achivement_details_2?.achived_year2
              ? dayjs(
                  user?.profile_completion?.achivement_details_2?.achived_year2
                ).format("YYYY")
              : ""}
          </p>
        </div>
      </div>
      {/* ------------------------Achivements-------------------------------------------- */}

      <>
        <Modal open={open5}>
          <ModalDialog
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            // sx={(theme) => ({
            //   [theme.breakpoints.only("xs")]: {
            //     top: "unset",
            //     bottom: 0,
            //     left: 0,
            //     right: 0,
            //     borderRadius: 0,
            //     transform: "none",
            //     maxWidth: "unset",
            //   },
            // })}
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                position: "absolute",
                overflowY: "auto",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                maxWidth: "100%",
                width: "100%", // Adjust width as per your requirement
                maxHeight: "100%", // Adjust max height as per your requirement
              },
            })}
          >
            <h2 class="text-md font-semibold text-[#0F3D79] py-2">
              Achievement 1
            </h2>
            <Textarea
              placeholder="Type here…"
              value={achivemenst.achivement1}
              onChange={handleAchiveChange}
              minRows={2}
              maxRows={4}
              endDecorator={
                <Box
                  sx={{
                    display: "flex",
                    gap: "var(--Textarea-paddingBlock)",
                    pt: "var(--Textarea-paddingBlock)",
                    borderTop: "1px solid",
                    borderColor: "divider",
                    flex: "auto",
                  }}
                >
                  <Typography
                    level="body-xs"
                    sx={{
                      ml: "auto",
                      color:
                        achivemenst.achivement1.length < 380 ? "black" : "red",
                    }}
                  >
                    {achivemenst.achivement1?.length < 380
                      ? `${achivemenst.achivement1.length} character(s)`
                      : characterLimit}
                  </Typography>
                </Box>
              }
              sx={{
                minWidth: 500,
              }}
            />

            {/* <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Achievement</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Achievement"
                  name="achivement1" 
                  value={achivemenst.achivement1}
                  onChange={handleAchiveChange}
                
                />
              </FormControl>
            </div> */}

            <div style={{ marginTop: "20px" }}>
              <FormControl variant="outlined">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="achived_year1"
                    slotProps={{ textField: { size: "small", error: error } }}
                    label="Year of Achievement"
                    views={["year"]}
                    value={dayjs(achived_year1)}
                    onChange={handelAchYear1}
                    shouldDisableYear={isInCurrentYear}
                  />
                  {error && (
                    <FormHelperText error={error}>Invalid year</FormHelperText>
                  )}
                </LocalizationProvider>
              </FormControl>
            </div>

            <h2 class="text-md font-semibold text-[#0F3D79] py-2">
              Achievement 2
            </h2>
            <Textarea
              placeholder="Type here…"
              value={achivementState.achivement2}
              onChange={handleAchiveChange1}
              minRows={2}
              maxRows={4}
              endDecorator={
                <Box
                  sx={{
                    display: "flex",
                    gap: "var(--Textarea-paddingBlock)",
                    pt: "var(--Textarea-paddingBlock)",
                    borderTop: "1px solid",
                    borderColor: "divider",
                    flex: "auto",
                  }}
                >
                  <Typography
                    level="body-xs"
                    sx={{
                      ml: "auto",
                      color:
                        achivementState.achivement2.length < 380
                          ? "black"
                          : "red",
                    }}
                  >
                    {achivementState.achivement2?.length < 380
                      ? `${achivementState.achivement2.length} character(s)`
                      : characterLimit}
                  </Typography>
                </Box>
              }
              sx={{
                minWidth: 500,
              }}
            />

            {/* <div style={{ marginTop: "10px" }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Achievement</InputLabel>
                <OutlinedInput
                  type="text"
                  required
                  label="Achievement"
                  name="achivement1" 
                  value={achivemenst.achivement1}
                  onChange={handleAchiveChange}
                
                />
              </FormControl>
            </div> */}

            <div style={{ marginTop: "20px" }}>
              <FormControl variant="outlined">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="achived_year1"
                    slotProps={{ textField: { size: "small", error: error } }}
                    label="Year of Achievement"
                    views={["year"]}
                    value={dayjs(achived_year2)}
                    onChange={handelAchYear2}
                    shouldDisableYear={isInCurrentYear}
                  />
                  {error && (
                    <FormHelperText error={error}>Invalid year</FormHelperText>
                  )}
                </LocalizationProvider>
              </FormControl>
            </div>

            <div className="flex justify-end  mt-4">
              <Button variant="outlined" color="neutral" onClick={handelClose5}>
                Cancel
              </Button>
              <Button
                variant="solid"
                color="contained"
                style={{
                  backgroundColor: "#0066A2",
                  color: "white",
                  marginLeft: "10px",
                }}
                onClick={achivemehntSumbit}
              >
                Submit{" "}
              </Button>
            </div>
          </ModalDialog>
        </Modal>
      </>
    </div>
  );
}

export default Achivements;
