
import React, { useState } from "react";
import Tab1 from "./showConnect";
import Tab2 from "./companyTab";
import { useDispatch, useSelector } from "react-redux";

import Connected from "./connected";
import RequestReceived from "./requestReceived";
import { individualUserSelector, setType } from "../../api/individualUser";
import { toast } from "react-toastify";
import MyFollowing from "./MyFollowing";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import MyConnections from "./MyConnection";
import MyRequest from "./MyRequest";

export default function Connection() {
  const { individualtoken, user, connected, request_received, type } =
    useSelector(individualUserSelector);

  
  const dispatch = useDispatch();

  const showToast = () => {
    toast.warning("This Page is Under Construction!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
            <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => dispatch(setType("people"))}
                  style={{
                    backgroundColor: type == "people" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <PeopleAltIcon />
                  </div>
                  <div>
                    <h4 class="text-md font-semibold text-[#0F3D79] mb-1">
                      Explore People
                    </h4>
                  </div>
                </div>

                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => dispatch(setType("company"))}
                  style={{
                    backgroundColor: type == "company" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <PeopleAltIcon />
                  </div>
                  <div>
                    <h4 class="text-md font-semibold text-[#0F3D79] mb-1">
                      Explore Organisations 
                    </h4>
                  </div>
                </div>
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => dispatch(setType("followings"))}
                  style={{
                    backgroundColor:
                      type == "followings" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <PeopleAltIcon />
                  </div>
                  <div>
                    <h4 class="text-md font-semibold text-[#0F3D79] mb-1">
                      Following
                    </h4>
                  </div>
                </div>
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => dispatch(setType("MyConnections"))}
                  style={{
                    backgroundColor:
                      type == "MyConnections" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <PeopleAltIcon />
                  </div>
                  <div>
                    <h4 class="text-md font-semibold text-[#0F3D79] mb-1">
                      My Connections
                    </h4>
                  </div>
                </div>
                <div
                  class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                  onClick={() => dispatch(setType("MyRequest"))}
                  style={{
                    backgroundColor:
                      type == "MyRequest" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                    <PeopleAltIcon />
                  </div>
                  <div>
                    <h4 class="text-md font-semibold text-[#0F3D79] mb-1">
                      My Requests
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="w-full lg:w-[78%] px-3"
              style={{
                display:
                  type === "my-connection" || type === "my-requests"
                    ? "none"
                    : "block",
              }}
            >
              <div class="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div style={{ display: type == "company" ? "block" : "none" }}>
                  <Tab2 />
                </div>
                <div style={{ display: type == "people" ? "block" : "none" }}>
                  <Tab1 />
                </div>
                <div
                  style={{ display: type == "followings" ? "block" : "none" }}
                >
                  <MyFollowing />
                </div>
                <div
                  style={{
                    display: type == "MyConnections" ? "block" : "none",
                  }}
                >
                  <MyConnections />
                </div>
                <div
                  style={{ display: type == "MyRequest" ? "block" : "none" }}
                >
                  <MyRequest />
                </div>
                <div
                  style={{
                    display: type === "my-connection" ? "block" : "none",
                  }}
                >
                  <Connected />
                </div>
              </div>
            </div>

            <div class="w-full lg:w-[78%] px-3">
              <div
                class="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl"
                style={{ display: type === "my-connection" ? "block" : "none" }}
              >
                <Connected />
              </div>
              <div
                class="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl"
                style={{ display: type === "my-requests" ? "block" : "none" }}
              >
                <RequestReceived />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
