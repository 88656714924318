import React from 'react'
import Logo from '../images/logo.png'

export default function Useragreement() {
  return (
    <div>
            <section
        class=" max-w-screen-xl min-h-[78vh] px-4 py-6 mx-auto text-justify mb-14"
        style={{ fontFamily: "Lato" }}
      >
           <img
                    class="block"
                    src={Logo}
                    alt=""
                  />
                
              
        <ul className="list-disc">
          <p class="mb-2 md:mb-5 md:mt-5  text-[20px] md:text-[25px] text-center text-[#0F3D79] font-bold font-heading">
          User Agreement
          </p>

          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          Please read the terms and conditions of use carefully before using this app.
          </p>

          <p className="md:text-[16px] text-[14px] text-gray-600  mb-5">
          We maintain this Application (BHARC) as a service to our customers, and by using our app you are agreeing to comply with and be bound by the following terms of use. Please review the following terms and conditions carefully, and check them periodically for changes. If you do not agree to the terms and conditions, you should not review information or obtain, services or products from this App.{" "}
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Acceptance of Agreement : </span> You agree to the terms and conditions outlined in this Terms and Conditions of use Agreement (“Agreement”) with respect to our App (the “App”). This Agreement constitutes the entire and only agreement between us and you, and supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the App, the content, products or services provided by or through the App, and the subject matter of this Agreement. This Agreement may be amended by us at any time and from time to time without specific notice to you. The latest Agreement will be posted on the App, and you should review this Agreement prior to using the App.
          </p>

          <p className="md:text-[16px] text-[14px] text-gray-600  mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Copyright : </span> The content, organisation, graphics, design, compilation, magnetic translation, digital conversion and other matters related to the App are protected under applicable copyrights, trademarks, registered trademarks and other proprietary (including but not limited to intellectual property) rights. The copying, redistribution, use or publication by you of any such matters or any part of the App, except as allowed by Section 4, is strictly prohibited. You do not acquire ownership rights to any content, document or other materials viewed through the App. The posting of information or materials on the App does not constitute a waiver of any right in such information and materials.
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Fraud : </span> By becoming a member, you confirm that the information provided in this form is true and that you agree to abide by the Terms and Conditions of use of this App. Please note that your membership can be cancelled without notice if it is determined that false or misleading information has been provided, the Terms and Conditions of use have been violated, or other abuses have occurred as determined by BHARC INDIA PRIVATE LIMITED in its sole discretion. If membership has been revoked, BHARC INDIA PRIVATE LIMITED reserves the right to refuse application or readmission to the membership program.
          </p>

          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Limited Right to Use : </span>Limited Right to Use: The viewing, printing or downloading of any content, graphic, form or document from the App grants you only a limited, nonexclusive license for use solely by you for your own personal use and not for republication, distribution, assignment, sublicense, sale, preparation of derivative works or other use. No part of any content, form or document may be reproduced in any form or incorporated into any information retrieval system, electronic or mechanical, other than for your personal use (but not for resale or redistribution).
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Editing, Deleting and Modification : </span> We reserve the right in our sole discretion to edit or delete any documents, information or other content appearing on the App, including this Agreement, without further notice to users of the App.
          </p>
       
          <p className="md:text-[16px] text-[14px] text-gray-600">
          <span className="text-[#0F3D79] font-semibold text-lg">Indemnification : </span> You agree to indemnify, defend and hold us and our partners, attorneys, staff and affiliates (collectively, “Affiliated Parties”) harmless from any liability, loss, claim and expense, including reasonable attorney’s fees, related to your violation of this Agreement or use of the App.
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600">
          <span className="text-[#0F3D79] font-semibold text-lg">Non Transferable : </span> Your right to use the App is not transferable. Any password or right given to you to obtain information or documents is not transferable and may only be used by you.
          </p>

          <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
          Dos 
          </p>
          <li className="md:text-[16px] text-[14px] text-slate-600">
        	Provide accurate information to us and keep it updated
            </li>
            <li className="md:text-[16px] text-[14px] text-slate-600">
        	Use your real name on your profile name and use the services in a professional manner
            </li>

          <p class="mb-2 md:mb-2 md:mt-10 text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
          Don’ts
          </p>
          <li className="md:text-[16px] text-[14px] text-slate-600">
        	
 Create false identity and don’t violate intellectual property rights of others.

            </li>
            <li className="md:text-[16px] text-[14px] text-slate-600 mb-5">
        	Post of anything contains viruses
            </li>
          {/* <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Use of your personal information
          </p> */}
          <p className="md:text-[16px] text-[14px] text-gray-600 mb-5">
          <span className="text-[#0F3D79] font-semibold text-lg">Disclaimer : </span> The information from or through the app are provided "as-is," "as available," and all warranties, express or implied, are disclaimed. The information and services may contain bugs, errors, problems, or other limitations. We and our affiliated parties have no liability whatsoever for your use of any information or service. In particular, but not as a limitation, we and our affiliated parties are not liable for any indirect, special, incidental, or consequential damages (including damages for loss of business, loss of profits, litigation, or the like), whether based on breach of contract, breach of warranty, tort (including negligence), product liability, or otherwise, even if advised of the possibility of such damages. The negation of damages set forth above are fundamental elements of the basis of the bargain between us. This app and the information would not be provided without such limitations. No advice or information, whether oral or written, obtained by you from us through the app shall create any warranty, representation, or guarantee not expressly stated in this agreement.
          </p>
          <p className="md:text-[16px] text-[14px] text-gray-600 ">
            From time to time, we may use customer information for new,
            unanticipated uses not previously disclosed in our privacy notice.
            If our information practices change at some time in the future we
            will use for these new purposes only, data collected from the time
            of the policy change forward will adhere to our updated practices
          </p>
          {/* <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Changes to this privacy policy
          </p> */}
          <p className="md:text-[16px] text-[14px] text-gray-600">
          <span className="text-[#0F3D79] font-semibold text-lg">Limits : </span> All responsibility or liability for any damages caused by viruses contained within the electronic file containing the form or document is disclaimed. WE WILL NOT BE LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY KIND THAT MAY RESULT FROM USE OF OR INABILITY TO USE OUR APP. Our maximum liability to you under all circumstances will be equal to the price you pay for any Products, services or information.
          </p>

          {/* <p class="mb-2 md:mb-2 md:mt-10  text-[20px] md:text-[22px] text-[#0F3D79] font-bold font-heading">
            Accessing your personal information
          </p> */}
          <p className="md:text-[16px] text-[14px] text-gray-600">
          <span className="text-[#0F3D79] font-semibold text-lg">Use of Information : </span> We reserve the right, and you authorise us, to the use and assignment of all information regarding App uses by you and all information provided by you in any manner consistent with our Privacy Policy.
          </p>
          
        </ul>
      </section>
    </div>
  )
}
