import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import WorkIcon from "@mui/icons-material/Work";
import { FaMinus } from "react-icons/fa";
import pdfff from "../../images/pdff.pdf";
import ExploreIcon from "@mui/icons-material/Explore";
import { useLocation } from "react-router";

import { individualUserSelector } from "../../api/individualUser";
import {
  exploreSelector,
  fetchAllExplore,
  getCurrentExploreSuccessfull,
  saveExplore,
  unSaveExplore,
} from "../../api/explore";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function ViewOtherEvents() {
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Define dispatch
  const { pathname } = useLocation();

  // Access user data and token from Redux state
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );

  // Function to save/unsave a topic
  const handleSaveTopic = (exploreId) => {
    if (user?.explore_saved?.some((saved) => saved._id === exploreId)) {
      dispatch(unSaveExplore(user?._id, exploreId, individualtoken));
    } else {
      dispatch(saveExplore(user?._id, exploreId, individualtoken));
    }
  };

  // Hardcoded publish data
  const currentPublish = {
    publish_posted_by_user: user,
    certificate_name: "Conference Certificate for Invention",
    description: "This is a description of the Conference.",
    document: pdfff,
  };

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/explore">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("explore")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/OtherEvents">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Other Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/add">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedEvents">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Events
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/SavedAds">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Saved Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    <div className="m-4 d-flex">
                      <h2 className="text-lg font-semibold text-[#0F3D79]">
                        View OtherEvents
                        <span className="text-sm"> </span>
                      </h2>
                    </div>

                    {/* <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                      <button
                        className="py-2 px-6 flex items-center text-white font-semibold border rounded-xl bg-[#0F3D79] w-full sm:w-auto"
                        type="button"
                        
                      >
                        <FaMinus className="mr-2" /> Delete
                      </button>
                    </div> */}
                  </div>

                  <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
                    <div className="w-full lg:w-1/2 bg-white mb-4 lg:mb-0 h-[500px] overflow-auto">
                      <div className="border p-4 rounded-md h-[500px]">
                        <div className="flex items-center mb-4">
                          <img
                            src={
                              user?.profile_completion?.profile_pic ||
                              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                            }
                            alt="Profile"
                            className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                          />
                          <div>
                            <h3 className="text-[#0F3D79] text-md font-semibold">
                              {user?.name}
                            </h3>
                            <p className="text-sm text-gray-500">
                              {user?.profile_completion?.designation || ""}
                            </p>
                          </div>
                        </div>

                        <h4 className="mt-4 text-[#0F3D79] text-md font-semibold">
                          {currentPublish?.certificate_name || ""}
                        </h4>
                        <p className="mt-2 text-gray-700">
                          {currentPublish?.description || ""}
                        </p>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/2 flex justify-center ml-2">
                      <iframe
                        src={currentPublish?.document}
                        width="100%"
                        height="500px"
                        style={{ border: "none", backgroundColor: "white" }}
                        title="PDF Viewer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ViewOtherEvents;
